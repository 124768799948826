import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Image } from "antd";
import FormInput from "../../Components/FormInput";
import { get, post, patch } from "../../services/RestService";
import { openNotification, options } from "../../helpers";
import UploadImage from "../../Components/UploadImage";
import UploadFile from "../../Components/UploadFile";
import SelectBox from "../../Components/SelectBox";
import { Recorder } from "react-voice-recorder";
import "react-voice-recorder/dist/index.css";
import "./styles.css";

const FormModal = ({ method, testId, closeModal, getTableData, data }) => {
  const [userData, setUserData] = useState();
  const [image, setImage] = useState();
  const [imagePath, setImagePath] = useState();
  const [file, setFile] = useState();
  const edit = method === "edit";
  const view = method === "view";
  const create = method === "create";

  const [audioDetails, setAudioDetails] = useState({
    url: null,
    blob: null,
    chunks: null,
    duration: {
      h: 0,
      m: 0,
      s: 0,
    },
  });
  const handleReset = () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    };
    setAudioDetails(reset);
  };

  const onSubmit = (values) => {
    const formdata = new FormData();
    Object.keys(values).forEach((key) => formdata.append(key, values[key]));
    formdata.append("testId", testId);
    formdata.append("questionImage", image ? image : imagePath);
    formdata.append("questionAudio", file);

    post("/admin/createQuestion", formdata, options)
      .then((res) => {
        console.log(res);
        if (res.status) {
          openNotification(res.message);
          closeModal();
          getTableData();
        } else {
          openNotification(res.error, 'error');
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(err.error, 'error');
      });
  };

  useEffect(() => {
    if (edit || view) setUserData(data);
    setImagePath(data.questionImage);
    setFile(data.questionAudio);
  }, [edit, view, setUserData, data]);

  const onEdit = (values) => {
    const formdata = new FormData();
    formdata.append("testId", testId);
    formdata.append("questionId", data._id);
    formdata.append("questionImage", image ? image : imagePath);
    formdata.append("questionAudio", file);
    Object.keys(values).forEach((key) => formdata.append(key, values[key]));

    patch("/admin/updateQuestion", formdata, options)
      .then((res) => {
        console.log(res);
        if (res.status) {
          openNotification(res.message);
          closeModal();
          getTableData();
        } else {
          openNotification(res.message, 'error');
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(err.error, 'error');
      });
  };

  console.log(file);

  const answerSelect = [
    { value: "a", title: "A" },
    { value: "b", title: "B" },
    { value: "c", title: "C" },
    { value: "d", title: "D" },
  ];

  return (
    <div>
      <div>
        {((edit && userData) || (view && userData) || create) && (
          <div style={{ marginTop: 10 }}>
            <div>
              {(image || imagePath) && (
                <Image
                  style={{ width: 100, height: 100, objectFit: "contain" }}
                  src={image ? URL.createObjectURL(image) : imagePath}
                />
              )}
              <p>Question Image</p>
              <UploadImage image={image} setImage={setImage} />
              {file && <audio src={file} style={{ marginTop: 10 }} controls />}
              <p>Question Audio File</p>
              <UploadFile file={file} setFile={setFile} />
              <p style={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
                <b>Or Record</b>
              </p>
              <div>
                <Recorder
                  record={true}
                  title={"New recording"}
                  hideHeader
                  audioURL={audioDetails.url}
                  handleAudioStop={(data) => setAudioDetails(data)}
                  handleAudioUpload={(data) => {
                    setFile(data)
                    console.log(data);
                  }}
                  handleReset={() => handleReset()}
                />
              </div>
            </div>
            <Form onFinish={edit ? onEdit : onSubmit} initialValues={userData}>
              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p>Question</p>
                  <FormInput name="question" />
                </Col>
              </Row>

              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p>Answer</p>
                  <SelectBox data={answerSelect} name="answer" />
                </Col>
              </Row>

              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p>Option A</p>
                  <FormInput name="optionA" />
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p>Option B</p>
                  <FormInput name="optionB" />
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p>Option C</p>
                  <FormInput name="optionC" />
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p>Option D</p>
                  <FormInput name="optionD" />
                </Col>
              </Row>

              {!view && (
                <Button type="primary" htmlType="submit">
                  {edit ? "Edit" : "Create"} Question
                </Button>
              )}
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormModal;
