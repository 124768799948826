import React, { useState, useEffect } from "react";
import { Row, Col, Input, Table, Switch, DatePicker, Image } from "antd";
import { get, patch, deleteService } from "../../services/RestService";
import { DeleteFilled, EyeFilled, EditFilled } from "@ant-design/icons";
import { Button } from "antd";
import { downloadExcelFile, openNotification, options } from "../../helpers";
import { useHistory } from "react-router";

const { RangePicker } = DatePicker;

const Offers = () => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const searchByName = (name) => {
    if (name !== "") {
      console.log(name);
      const res = data.filter((sd) => {
        return (
          sd?.nameEnglish.toString().toLowerCase().includes(search) ||
          sd?.nameArabic.toString().toLowerCase().includes(search)
        );
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const searchByDate = (dates) => {
    if (dates[0] !== "" && dates[1] !== "") {
      console.log(dates);
      const res = data.filter((sd) => {
        return (
          sd?.createdAt.substring(0, 10) >= dates[0] &&
          sd?.createdAt.substring(0, 10) <= dates[1]
        );
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const getData = () => {
    setLoading(true);
    const t = localStorage.getItem("token");
    const token = `Bearer ${t}`;
    var options = {
      headers: {
        Authorization: token,
      },
    };
    get("/admin/getOffers", options)
      .then((data) => {
        console.log(data);
        setData(data.reverse());
        setDataFilter(data.reverse());
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const updateStatus = (id, checked) => {
    setLoading(true);

    const data = {
      offerId: id,
    };
    patch("/admin/activateOffer", data, options)
      .then((data) => {
        console.log(data);
        openNotification("Status Changed Successfully");
        getData();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const deleteUser = (id) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("offerId", id);

    deleteService(`/admin/deleteOffer`, formData, options)
      .then((data) => {
        console.log(data);
        if (data.status) {
          openNotification("Offer Deleted Successfully");
          getData();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Image",
      dataIndex: "imagePath",
      key: "imagePath",
      render: (_, data) => (
        <Image
          style={{ width: 50, height: 50, objectFit: "contain" }}
          src={data && data.imagePath}
        />
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, data) => <p>{data && data?.createdAt.substring(0, 10)}</p>,
    },
    {
      title: "Name (English)",
      dataIndex: "nameEnglish",
      key: "nameEnglish",
      render: (_, data) => <p>{data.nameEnglish && data.nameEnglish}</p>,
    },
    {
      title: "Name (Arabic)",
      dataIndex: "nameArabic",
      key: "nameArabic",
      render: (_, data) => <p>{data.nameArabic && data.nameArabic}</p>,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (_, data) => <p>{data.discount && data.discount}</p>,
    },
    {
      title: "Status",
      dataIndex: "activeStatus",
      key: "activeStatus",
      render: (_, data) => (
        <Switch
          checked={data.activeStatus}
          onChange={(e) => updateStatus(data._id, e)}
        />
      ),
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "InActive",
          value: false,
        },
      ],
      onFilter: (value, data) => data.activeStatus === value,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, data) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <EyeFilled
            onClick={() => {
              history.push(`offerform?type=view&id=${data._id}`);
            }}
            style={{
              color: "grey",
              cursor: "pointer",
              fontSize: 25,
              marginRight: 10,
            }}
          />
          <EditFilled
            onClick={() => {
              history.push(`offerform?type=edit&id=${data._id}`);
            }}
            style={{
              color: "grey",
              cursor: "pointer",
              fontSize: 25,
              marginRight: 10,
            }}
          />
          <DeleteFilled
            onClick={() => {
              deleteUser(data._id);
            }}
            style={{ color: "grey", cursor: "pointer", fontSize: 25 }}
          />
        </div>
      ),
    },
  ];

  return (
    <div id="users" style={{ padding: 30 }}>
      <div className="users-wrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Offers</h1>
          <div>
            <Button onClick={() => downloadExcelFile(data)} type="primary">
              Download
            </Button>
            <Button
              onClick={() => {
                history.push(`offerform?type=create`);
              }}
              style={{ marginLeft: 30 }}
              type="primary"
            >
              Create Offer
            </Button>
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div>
                <p>Search by Title</p>
                <Input
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    searchByName(e.target.value);
                  }}
                  placeholder="Search By Title"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <p>Search by Date</p>
              <RangePicker
                style={{ width: "100%" }}
                onChange={(e, d) => searchByDate(d)}
              />
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: 30 }}>
          <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={data}
          />
        </div>
      </div>
    </div>
  );
};

export default Offers;
