import React, { useState, useEffect } from "react";
import { Layout, Menu, Dropdown, Badge, Divider } from "antd";
import "./styles.scss";
import {
  DashboardOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  NotificationOutlined,
  DollarOutlined,
  CheckCircleOutlined,
  ContainerOutlined,
  SettingOutlined,
  LayoutOutlined,
  StarOutlined,
  FireOutlined,
  ReadOutlined,
  LikeOutlined,
  UnorderedListOutlined,
  TeamOutlined,
  SnippetsOutlined,
  CloseCircleOutlined,
  BellFilled,
} from "@ant-design/icons";
import Logo from "../../assets/logocivil.png";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import { logout, options } from "../../helpers";
import { io } from "socket.io-client";
import { get } from "../../services/RestService";

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

const SiderLayout = ({ children }) => {
  const [collapsed, setCollapse] = useState(false);
  const [notificationList, setNotification] = useState([]);

  const onCollapse = (collapsed) => {
    setCollapse(collapsed);
  };
  const history = useHistory();
  const isMobileScreen = useMediaQuery({ query: "(max-width: 680px)" });

  const getNotification = () => {
    get("/admin/getNotifications", options)
      .then((res) => {
        console.log(res);
        setNotification(res?.reverse()?.filter((x) => x?.status === "unread"));
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const socket = io("https://api.civil.ae/", {
    transports: ["websocket"],
  });

  socket.on("connect", () => {
    console.log(socket.id); // x8WIv7-mJelg7on_ALbx
  });

  socket.on("notification", (data) => {
    console.log("shippuen = = = = == ", data);
    // getNotification();
  });

  useEffect(() => {
    getNotification();
  }, []);

  useEffect(() => {
    if (isMobileScreen) {
      setCollapse(true);
    }
  }, [isMobileScreen]);

  const notifications = (
    <>
      <div
        style={{
          backgroundColor: "white",
          padding: 10,
          borderRadius: 10,
          maxWidth: 300,
          border: "1px solid black",
        }}
      >
        <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
          Notifications
        </h4>
        <Divider />
        <div style={{ cursor: "pointer" }} className="main-notifs">
          {notificationList &&
            notificationList.map((data, i) => (
              <p
                onClick={() => {
                  // history.push("/notifications");
                  history.push({
                    pathname: "/notifications",
                    showModal: true,
                    data,
                  });
                }}
                key={i}
                className="notif-p"
              >
                {data.title}
              </p>
            ))}
        </div>
      </div>
    </>
  );

  return (
    <div>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
          className="sider-menu"
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
        >
          <div className="logo">
            <center>
              <img
                src={Logo}
                style={{
                  width: !collapsed && 100,
                  height: collapsed ? 60 : 120,
                  padding: 20,
                }}
                alt="logo"
              />
            </center>
          </div>
          <Menu
            style={{ marginBottom: 20 }}
            theme="dark"
            defaultSelectedKeys={[history.location.pathname]}
            mode="vertical"
          >
            <Menu.Item
              key="/home"
              icon={<DashboardOutlined />}
              onClick={() => history.push("/home")}
            >
              Dashboard
            </Menu.Item>
            <Menu.Item
              key="/users"
              icon={<UsergroupAddOutlined />}
              onClick={() => history.push("/users")}
            >
              Users
            </Menu.Item>
            <Menu.Item
              key="/students"
              icon={<TeamOutlined />}
              onClick={() => history.push("/students")}
            >
              Students
            </Menu.Item>
            <Menu.Item
              key="/trainers"
              icon={<UserOutlined />}
              onClick={() => history.push("/trainers")}
            >
              Trainers
            </Menu.Item>
            <Menu.Item
              key="/categories"
              icon={<UnorderedListOutlined />}
              onClick={() => history.push("/categories")}
            >
              Categories
            </Menu.Item>
            <Menu.Item
              key="/courses"
              icon={<ReadOutlined />}
              onClick={() => history.push("/courses")}
            >
              Courses
            </Menu.Item>
            <Menu.Item
              key="/test"
              icon={<SnippetsOutlined />}
              onClick={() => history.push("/test")}
            >
              Test
            </Menu.Item>
            <Menu.Item
              key="/schedule-test"
              icon={<SnippetsOutlined />}
              onClick={() => history.push("/schedule-test")}
            >
              Schedule Test
            </Menu.Item>
            <Menu.Item
              key="/cancel-booking"
              icon={<CloseCircleOutlined />}
              onClick={() => history.push("/cancel-booking")}
            >
              Cancel Booking
            </Menu.Item>
            <Menu.Item
              key="/jobs"
              icon={<CheckCircleOutlined />}
              onClick={() => history.push("/jobs")}
            >
              Jobs Management
            </Menu.Item>
            <Menu.Item
              key="/rating"
              icon={<StarOutlined />}
              onClick={() => history.push("/rating")}
            >
              Rating
            </Menu.Item>
            <Menu.Item
              key="/promotions"
              icon={<LikeOutlined />}
              onClick={() => history.push("/promotions")}
            >
              Promotions
            </Menu.Item>
            <Menu.Item
              key="/trainer-promotion"
              icon={<LikeOutlined />}
              onClick={() => history.push("/trainer-promotion")}
            >
              Trainer Promotions
            </Menu.Item>
            <Menu.Item
              key="/offers"
              icon={<FireOutlined />}
              onClick={() => history.push("/offers")}
            >
              Special Offers
            </Menu.Item>
            <Menu.Item
              key="/notifications"
              icon={<NotificationOutlined />}
              onClick={() => history.push("/notifications")}
            >
              Notifications
            </Menu.Item>
            <Menu.Item
              key="/documents"
              icon={<ContainerOutlined />}
              onClick={() => history.push("/documents")}
            >
              Documents
            </Menu.Item>
            <Menu.Item
              key="/transaction-history"
              icon={<DollarOutlined />}
              onClick={() => history.push("/transaction-history")}
            >
              Transaction History
            </Menu.Item>
            <Menu.Item
              key="/site-setting"
              icon={<SettingOutlined />}
              onClick={() => history.push("/site-setting")}
            >
              Site Settings
            </Menu.Item>
            <SubMenu
              style={{ marginBottom: 100 }}
              key="Website"
              icon={<LayoutOutlined />}
              title="Website Pages"
            >
              <Menu.Item key="/header" onClick={() => history.push("/header")}>
                Header
              </Menu.Item>
              <Menu.Item
                key="/homesetting"
                onClick={() => history.push("/homesetting")}
              >
                Home
              </Menu.Item>
              <Menu.Item key="/about" onClick={() => history.push("/about")}>
                About
              </Menu.Item>
              <Menu.Item
                key="/contact"
                onClick={() => history.push("/contact")}
              >
                Contact
              </Menu.Item>
              <Menu.Item
                key="/gallery"
                onClick={() => history.push("/gallery")}
              >
                Gallery
              </Menu.Item>
              <Menu.Item key="/footer" onClick={() => history.push("/footer")}>
                Footer
              </Menu.Item>
              <Menu.Item key="/terms" onClick={() => history.push("/terms")}>
                Terms & Condition
              </Menu.Item>
              <Menu.Item
                key="/privacy-policy"
                onClick={() => history.push("/privacy-policy")}
              >
                Privacy Policy
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout
          style={{ marginLeft: collapsed ? 80 : 200 }}
          className="site-layout"
        >
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h1 style={{ color: "white", marginLeft: 10 }}>
                Civil Admin Panel
              </h1>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: 30 }}>
                  <Dropdown
                    overlay={notifications}
                    trigger={["click"]}
                    placement="bottomCenter"
                  >
                    <Badge
                      size="default"
                      count={notificationList && notificationList.length}
                      offset={[-5, 10]}
                    >
                      <BellFilled
                        style={{
                          color: "white",
                          fontSize: 40,
                          cursor: "pointer",
                        }}
                      />
                    </Badge>
                  </Dropdown>
                </div>
                <h1
                  onClick={() => logout(history)}
                  style={{ color: "white", marginRight: 30, cursor: "pointer" }}
                >
                  Logout
                </h1>
              </div>
            </div>
          </Header>
          <Content style={{ margin: "0 16px" }}>{children}</Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default SiderLayout;
