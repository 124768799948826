import React, { useState, useEffect } from "react";
import { Form, Row, Col, Spin, Button, Divider } from "antd";
import FormInput from "../../Components/FormInput";
import { get, patch } from "../../services/RestService";
import { options, openNotification } from "../../helpers";
import FormTextarea from "../../Components/FormTextarea";

const PrivacyPolicy = () => {
  const [loading, setLoading] = useState();
  const [data, setData] = useState();

  const getData = () => {
    setLoading(true);
    get("/admin/getOnePolicy", options)
      .then((data) => {
        console.log(data);
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const updateSetting = (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => formData.append(key, values[key]));

    patch(`/admin/updatePolicy/${data._id}`, formData, options)
      .then((res) => {
        if (res.status) {
          openNotification(res.message);
          getData();
        } else {
          openNotification(res.error, 'error');
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(err.error, 'error');
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return loading ? (
    <Spin style={{ marginTop: 20 }} />
  ) : (
    <div style={{ padding: 30 }}>
      <div style={{ backgroundColor: "white", padding: 20 }}>
        <h1>Privacy Policy</h1>
        <Divider />

        <div style={{ marginTop: 50 }}>
          <Form initialValues={data} onFinish={updateSetting}>
            <Row gutter={[10, 10]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div style={{ border: "1px solid lightgrey", padding: 20 }}>
                  <h1 style={{ textAlign: "center" }}>English</h1>
                  <h3>English Heading</h3>
                  <FormInput name="title" />
                  <h3>English Content</h3>
                  <FormTextarea name="description" />
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }} gutter={[10, 10]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div style={{ border: "1px solid lightgrey", padding: 20 }}>
                  <h1 style={{ textAlign: "center" }}>Arabic</h1>
                  <h3>Arabic Heading</h3>
                  <FormInput name="titleArabic" />
                  <h3>Arabic Content</h3>
                  <FormTextarea name="descriptionArabic" />
                </div>
              </Col>
            </Row>

            <div style={{ marginTop: 30, textAlign: "right" }}>
              <Button size="large" type="primary" htmlType="submit">
                Save Information
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
