import React, { useState, useEffect } from "react";
import { Row, Col, Select, Input, Table, Modal, Image, DatePicker } from "antd";
import { get, patch, deleteService, post } from "../../services/RestService";
import { DeleteFilled, EyeFilled, EditFilled } from "@ant-design/icons";
import { Button } from "antd";
import { downloadExcelFile, openNotification, options } from "../../helpers";
import FormModal from "./FormModal";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Promotions = () => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewModal, setViewModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [method, setMethod] = useState();

  const searchByName = (name) => {
    if (name !== "") {
      console.log(name);
      const res = data.filter((sd) => {
        return (
          sd?.titleEnglish.toString().toLowerCase().includes(search) ||
          sd?.titleArabic.toString().toLowerCase().includes(search)
        );
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const searchByDate = (dates) => {
    if (dates[0] !== "" && dates[1] !== "") {
      console.log(dates);
      const res = data.filter((sd) => {
        return (
          sd?.createdAt.substring(0, 10) >= dates[0] &&
          sd?.createdAt.substring(0, 10) <= dates[1]
        );
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const getData = () => {
    setLoading(true);
    get("/admin/getPromotions", options)
      .then((data) => {
        console.log(data);
        setData(data.reverse());
        setDataFilter(data.reverse());
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const updateStatus = (id, checked) => {
    setLoading(true);
    const data = {
      promotionId: id,
      activeStatus: checked,
    };
    patch("/admin/updatePromotion", data, options)
      .then((data) => {
        console.log(data);
        if (data.status) {
          openNotification("Status Changed Successfully");
          getData();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const deleteUser = (id) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("promotionId", id);

    deleteService(`/admin/deletePromotion`, formData, options)
      .then((data) => {
        console.log(data);
        if (data.status) {
          openNotification("Promotion Deleted Successfully");
          getData();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const sendPromotion = (id) => {
    const t = localStorage.getItem("token");
    const token = `Bearer ${t}`;
    var options = {
      headers: {
        Authorization: token,
      },
    };

    const formData = new FormData();
    formData.append("promotionId", id);
    post("/admin/sendPromotion", formData, options)
      .then((res) => {
        console.log(res);
        if (res.status) {
          openNotification(res.message);
        } else {
          openNotification(res.error, 'error');
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(err.error, 'error');
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Image",
      dataIndex: "promotionImage",
      key: "promotionImage",
      render: (_, data) => (
        <Image
          style={{ width: 50, height: 50, objectFit: "contain" }}
          src={data && data.promotionImage}
        />
      ),
    },
    {
      title: "Created_At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, data) => <p>{data && data?.createdAt.substring(0, 10)}</p>,
    },
    {
      title: "Title(English)",
      dataIndex: "titleEnglish",
      key: "titleEnglish",
      render: (_, data) => <p>{data.titleEnglish && data.titleEnglish}</p>,
    },
    {
      title: "Title(Arabic)",
      dataIndex: "titleArabic",
      key: "titleArabic",
      render: (_, data) => <p>{data.titleArabic && data.titleArabic}</p>,
    },
    {
      title: "Subject(English)",
      dataIndex: "subjectEnglish",
      key: "subjectEnglish",
      render: (_, data) => <p>{data.subjectEnglish && data.subjectEnglish}</p>,
    },
    {
      title: "Subject(Arabic)",
      dataIndex: "subjectArabic",
      key: "subjectArabic",
      render: (_, data) => <p>{data.subjectArabic && data.subjectArabic}</p>,
    },
    {
      title: "Send_Promotion",
      dataIndex: "activeStatus",
      key: "activeStatus",
      render: (_, data) => (
        <Button onClick={() => sendPromotion(data._id)} type="primary">
          Send
        </Button>
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, data) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <EyeFilled
            onClick={() => {
              setModalData(data);
              setMethod("view");
              setViewModal(true);
            }}
            style={{
              color: "grey",
              cursor: "pointer",
              fontSize: 25,
              marginRight: 10,
            }}
          />
          <EditFilled
            onClick={() => {
              setModalData(data);
              setMethod("edit");
              setViewModal(true);
            }}
            style={{
              color: "grey",
              cursor: "pointer",
              fontSize: 25,
              marginRight: 10,
            }}
          />
          <DeleteFilled
            onClick={() => {
              deleteUser(data._id);
            }}
            style={{ color: "grey", cursor: "pointer", fontSize: 25 }}
          />
        </div>
      ),
    },
  ];

  return (
    <div id="users" style={{ padding: 30 }}>
      <div className="users-wrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Promotions</h1>
          <div>
            <Button onClick={() => downloadExcelFile(data)} type="primary">
              Download
            </Button>
            <Button
              onClick={() => {
                setMethod("create");
                setViewModal(true);
              }}
              style={{ marginLeft: 30 }}
              type="primary"
            >
              Create Promotion
            </Button>
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div>
                <p>Search by Title</p>
                <Input
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    searchByName(e.target.value);
                  }}
                  placeholder="Search By Title"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <p>Search by Date</p>
              <RangePicker
                style={{ width: "100%" }}
                onChange={(e, d) => searchByDate(d)}
              />
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: 30 }}>
          <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={data}
          />
        </div>
      </div>
      <Modal
        destroyOnClose
        footer={false}
        title="Promotion"
        okText="Done"
        visible={viewModal}
        onCancel={() => setViewModal(false)}
        onOk={() => {
          setViewModal(false);
        }}
      >
        <FormModal
          closeModal={() => setViewModal(false)}
          getTableData={getData}
          data={modalData}
          method={method}
        />
      </Modal>
    </div>
  );
};

export default Promotions;
