import React, { useState, useEffect } from 'react'
import { Button, Form, Row, Col, Image } from 'antd'
import UploadImage from '../../Components/UploadImage'
import FormInput from '../../Components/FormInput'
import { post, patch } from '../../services/RestService'
import { openNotification } from '../../helpers'
import FormTextarea from '../../Components/FormTextarea'

const FormModal = ({ method, id, closeModal, getTableData, data }) => {
  const [ image, setImage ] = useState()
  const [imagePath, setImagePath] = useState()
  const [ userData, setUserData] = useState()
  const edit  = method === 'edit'
  const view  = method === 'view'
  const create  = method === 'create'

  const onSubmit = (values) => {

    const t = localStorage.getItem('token')
    const token = `Bearer ${t}`
    var options = {
      headers: { 
        'Authorization': token
      }
    }
    const formdata = new FormData();
  
    formdata.append("categoryImage", image ? image : imagePath);
    formdata.append("descriptionEnglish", values.descriptionEnglish);
    formdata.append("descriptionArabic", values.descriptionArabic);
    formdata.append("nameArabic", values.nameArabic);
    formdata.append("nameEnglish", values.nameEnglish);
   
    post('/admin/createCategory', formdata, options).then((res)=> {
      console.log(res)
      if(res.status) {
        openNotification(res.message)
        closeModal()
        getTableData()
      } else {
        openNotification(res.error, 'error')
      }
    }).catch((err) => {
      console.log(err)
      openNotification(err.error, 'error')
    })
  }


  useEffect(() => {
    if(edit || view)
      setUserData(data)
      setImagePath(data?.imagePath)
  }, [edit, view, setUserData, data])


  const onEdit = (values) => {

    const t = localStorage.getItem('token')
    const token = `Bearer ${t}`
    var options = {
      headers: { 
        'Authorization': token
      }
    }
    const formdata = new FormData();
    formdata.append("categoryId", data._id);
    formdata.append("categoryImage", image ? image : imagePath);
    formdata.append("descriptionEnglish", values.descriptionEnglish);
    formdata.append("descriptionArabic", values.descriptionArabic);
    formdata.append("nameArabic", values.nameArabic);
    formdata.append("nameEnglish", values.nameEnglish);

    patch('/admin/updateCategory', formdata, options).then((res)=> {
      console.log(res)
      if(res.status) {
        openNotification(res.message)
        closeModal()
        getTableData()
      } else {
        openNotification(res.error, 'error')
      }
    }).catch((err) => {
      console.log(err)
      openNotification(err.error, 'error')
    })
  }
  

  return (
    <div >
      <div >
        {(image || imagePath) && <div style={{ textAlign: 'center'}}>
            <Image src={image ? URL.createObjectURL(image) : imagePath} style={{ width: 100, heigt: 100, objectFit: 'contain', }} />
          </div>}
        
        {!view && <div style={{ marginTop: 10}}>
          <p>Upload Image</p>
          <UploadImage image={image} setImage={setImage} />
        </div>}

        {((edit && userData) || (view && userData) || create ) && 
        <div style={{ marginTop: 20}}>
          <Form onFinish={edit ? onEdit : onSubmit} initialValues={userData}>

            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <p>Category Name (English)</p>
                <FormInput name='nameEnglish' rules={[{ required: true, message: 'required' }]}/>
              </Col>
            </Row>
            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <p>Category Description (English)</p>
                <FormTextarea name='descriptionEnglish' rules={[{ required: true, message: 'required' }]} />
              </Col>
            </Row>
            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <p>Category Name (Arabic)</p>
                <FormInput name='nameArabic' rules={[{ required: true, message: 'required' }]}/>
              </Col>
            </Row>
            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <p>Category Description (Arabic)</p>
                <FormTextarea name='descriptionArabic' rules={[{ required: true, message: 'required' }]}/>
              </Col>
            </Row>

            
              {!view && <Button type='primary' htmlType='submit' >{edit ? 'Edit' : 'Create'} Category</Button>}
          </Form>
        </div>}
      </div>

    </div>
  )
}

export default FormModal
