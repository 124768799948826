import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Switch, Spin, Button } from 'antd'
import FormInput from '../../Components/FormInput'
import { get, patch } from '../../services/RestService'
import { options, openNotification } from '../../helpers'

const SiteSetting = () => {

  const [loading, setLoading] = useState()
  const [data, setData] = useState()

  const getData = () => {
    setLoading(true)
      get('/admin/getSettings',options).then((data) => {
        setData(data)
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  const updateSetting = (values) => {
    console.log(values)
  
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    formData.append('settingId', data._id)
   
    patch('/admin/updateSetting', formData, options).then((res)=> {
      console.log(res)
      if(res.status) {
        openNotification(res.message)
        getData()
      } else {
        openNotification(res.error, 'error')
      }
    }).catch((err) => {
      console.log(err)
      openNotification(err.error, 'error')
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return ( loading ? <Spin style={{ marginTop: 20}} /> :
    <div style={{ padding: 30}}>
      <div style={{ backgroundColor: 'white', padding: 20}}>
        <h1>
          Site Settings
        </h1>
        <div style={{ marginTop: 50}}>
          <Form initialValues={data} onFinish={updateSetting}>
            <Row gutter={[10, 10]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div style={{ border: '1px solid lightgrey', padding: 20}}>
                  <h3>Trainer Subscription Charges</h3>
                  <FormInput name='trainerSubscription' addonAfter={'AED'} />
                  <h3>Status</h3>
                  <Form.Item name='trainerStatus' valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div style={{ border: '1px solid lightgrey', padding: 20}}>
                  <h3>Student Subscription Charges</h3>
                  <FormInput name='studentSubscription' addonAfter={'AED'} />
                  <h3>Status</h3>
                  <Form.Item name='studentStatus' valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div style={{ border: '1px solid lightgrey', padding: 20}}>
                  <h3>Promotion Charges</h3>
                  <FormInput name='promotionCharge' addonAfter={'AED'} />
                  <h3>Status</h3>
                  <Form.Item name='promotionStatus' valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: 20}} gutter={[10, 10]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div style={{ border: '1px solid lightgrey', padding: 20}}>
                  <h3>Advance Test Charges</h3>
                  <FormInput name='advanceTestCharges' addonAfter={'AED'} />
                  <h3>Status</h3>
                  <Form.Item name='advanceTestChargesStatus' valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div style={{ border: '1px solid lightgrey', padding: 20}}>
                  <h3>Advance Test Question Limit</h3>
                  <FormInput name='advanceTestLimit' />
                  <h3>Status</h3>
                  <Form.Item name='advanceTestLimitStatus' valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div style={{ border: '1px solid lightgrey', padding: 20}}>
                  <h3>Basic Test Question Limit</h3>
                  <FormInput name='basicTestLimit' />
                  <h3>Status</h3>
                  <Form.Item name='basicTestLimitStatus' valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: 20}} gutter={[10, 10]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div style={{ border: '1px solid lightgrey', padding: 20}}>
                  <h3>Admin Commission</h3>
                  <FormInput name='adminCommission' addonAfter={'AED'} />
                  <h3>Status</h3>
                  <Form.Item name='adminStatus' valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <div style={{ marginTop: 30, textAlign: 'right'}}>
              <Button size='large' type='primary' htmlType='submit' >Save</Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default SiteSetting
