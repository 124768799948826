import React, { useState, useEffect } from "react";
import { Row, Col, Input, Table, Modal, DatePicker } from "antd";
import { get } from "../../services/RestService";
import { EyeFilled } from "@ant-design/icons";
import { options } from "../../helpers";
import { Button } from "antd";

const { RangePicker } = DatePicker

const TransactionHistory = () => {
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([])
  const [userData, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [viewModal, setViewModal] = useState(false);

  const searchByName = (name) => {
    if (name !== "") {
      console.log(name);
      const res = dataFilter.filter((sd) => {
        let fullName = sd?.student?.firstName + " " + sd?.student?.lastName;
        return fullName.toString().toLowerCase().includes(search);
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const search1ByName = (name) => {
    if (name !== "") {
      console.log(name);
      const res = dataFilter.filter((sd) => {
        let fullName = sd?.trainer?.firstName + " " + sd?.trainer?.lastName;
        return fullName.toString().toLowerCase().includes(search1);
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const searchByDate = (dates) => {
    if (dates[0] !== "" && dates[1] !== "") {
      console.log(dates);
      const res = dataFilter.filter((sd) => {
        return (
          sd?.createdAt.substring(0, 10) >= dates[0] &&
          sd?.createdAt.substring(0, 10) <= dates[1]
        );
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const getData = () => {
    setLoading(true);
    get("/admin/getTransactions", options)
      .then((data) => {
        console.log(data);
        setData(data.reverse());
        setDataFilter(data.reverse());
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Student",
      dataIndex: "student",
      key: "student",
      render: (_, data) => (
        <p>
          {data?.student?.firstName} {data?.student?.lastName}
        </p>
      ),
    },
    {
      title: "Trainer",
      dataIndex: "trainer",
      key: "trainer",
      render: (_, data) => (
        <p>
          {data?.trainer?.firstName} {data?.trainer?.lastName}
        </p>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, data) => (
        <p>{data.createdAt && data.createdAt.substring(0, 10)}</p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, data) => <p>{data?.amount}</p>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (_, data) => <p>{data?.type}</p>,
      filters: [
        {
          text: 'Course',
          value: 'Course',
        },
        {
          text: 'Test',
          value: 'Test',
        },
        {
          text: 'Promotion',
          value: 'Promotion',
        },
      ],
      onFilter: (value, data) => data.type === value
    },
    {
      title: "Course (English)",
      dataIndex: "item.nameEnglish",
      key: "item.nameEnglish",
      render: (_, data) => <p>{data?.item?.nameEnglish}</p>,
    },
    {
      title: "Course (Arabic)",
      dataIndex: "item.nameArabic",
      key: "item.nameArabic",
      render: (_, data) => <p>{data?.item?.nameArabic}</p>,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, data) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <EyeFilled
            onClick={() => {
              setUser(data);
              setViewModal(true);
            }}
            style={{
              color: "grey",
              cursor: "pointer",
              fontSize: 25,
              marginRight: 10,
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div id="users" style={{ padding: 30 }}>
      <div className="users-wrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Transaction History</h1>
          <div>
            <Button type="primary">Download</Button>
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div>
                <p>Search by Student</p>
                <Input
                  value={search}
                  onChange={(e) => {setSearch(e.target.value); searchByName(e.target.value)}}
                  placeholder="Search By Student"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div>
                <p>Search by Trainer</p>
                <Input
                  value={search1}
                  onChange={(e) => {setSearch1(e.target.value); search1ByName(e.target.value)}}
                  placeholder="Search By Account Name"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <p>Search by Date</p>
            <RangePicker style={{ width: '100%'}} onChange={(e, d) => searchByDate(d)} />
          </Col>
          </Row>
        </div>

        <div style={{ marginTop: 30 }}>
          <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={data.reverse()}
          />
        </div>
      </div>
      <Modal
        footer={false}
        title="View Transaction History"
        okText="Done"
        visible={viewModal}
        onCancel={() => setViewModal(false)}
        onOk={() => {
          setViewModal(false);
        }}
      >
        <div>
          {/* <center><Image style={{ width: 100 , height: 100, marginBottom: 30}} src={userData?.imagePath} /></center> */}
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Student Name</p>
              </b>
              <p>
                {userData?.student?.firstName} {userData?.student?.lastName}
              </p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Type</p>
              </b>
              <p>{userData?.type}</p>
            </Col>
          </Row>
          {userData?.type === "Course" && (
            <Row gutter={[10, 10]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <b>
                  <p>Course English</p>
                </b>
                <p>{userData?.item?.nameEnglish}</p>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <b>
                  <p>Course Arabic</p>
                </b>
                <p>{userData?.item?.nameArabic}</p>
              </Col>
            </Row>
          )}
          {userData?.type === "Test" && (
            <Row gutter={[10, 10]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <b>
                  <p>Test Title</p>
                </b>
                <p>{userData?.item?.title}</p>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <b>
                  <p>Test Type</p>
                </b>
                <p>{userData?.item?.testType}</p>
              </Col>
            </Row>
          )}
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Amount</p>
              </b>
              <p>{userData?.amount}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Created At</p>
              </b>
              <p>{userData?.createdAt?.substring(0, 10)}</p>
            </Col>
          </Row>
          {(userData?.type === "Test" || userData?.type === "Promotion") && (
            <Row gutter={[10, 10]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <b>
                  <p>Trainer Name</p>
                </b>
                <p>
                  {userData?.trainer?.firstName} {userData?.trainer?.lastName}
                </p>
              </Col>
            </Row>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default TransactionHistory;
