import React, { useState, useEffect } from "react";
import { Row, Col, Input, DatePicker, Table, Modal, Tag } from "antd";
import { get } from "../../services/RestService";
import { EyeFilled } from "@ant-design/icons";
import { Button } from "antd";
import { downloadExcelFile } from "../../helpers";

const { RangePicker } = DatePicker;

const Jobs = () => {
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");
  const [data, setData] = useState([]);
  const [dataFilter, setFilterData] = useState([]);
  const [userData, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [viewModal, setViewModal] = useState(false);

  const searchByName = (name) => {
    if (name !== "") {
      console.log(name);
      const res = data.filter((sd) => {
        let fullName = sd?.student?.firstName + " " + sd?.student?.lastName;
        return fullName.toString().toLowerCase().includes(search);
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const search1ByName = (name) => {
    if (name !== "") {
      console.log(name);
      const res = data.filter((sd) => {
        let fullName = sd?.trainer?.firstName + " " + sd?.trainer?.lastName;
        return fullName.toString().toLowerCase().includes(search1);
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const searchByDate = (dates) => {
    if (dates[0] !== "" && dates[1] !== "") {
      console.log(dates);
      const res = data.filter((sd) => {
        return (
          sd?.createdAt.substring(0, 10) >= dates[0] &&
          sd?.createdAt.substring(0, 10) <= dates[1]
        );
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const getData = () => {
    setLoading(true);
    const t = localStorage.getItem("token");
    const token = `Bearer ${t}`;
    var options = {
      headers: {
        Authorization: token,
      },
    };
    get("/admin/getJobs", options)
      .then((data) => {
        console.log(data);
        setData(data.reverse());
        setFilterData(data.reverse());
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, data) => (
        <p>{data.createdAt && data.createdAt.substring(0, 10)}</p>
      ),
    },
    {
      title: "Student",
      dataIndex: "student",
      key: "student",
      render: (_, data) => (
        <p>
          {data?.student?.firstName} {data?.student?.lastName}
        </p>
      ),
    },
    {
      title: "Trainer",
      dataIndex: "trainer",
      key: "trainer",
      render: (_, data) => (
        <p>
          {data?.trainer?.firstName} {data?.trainer?.lastName}
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, data) => (
        <Tag color={"volcano"}>{data?.status.toUpperCase()}</Tag>
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, data) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <EyeFilled
            onClick={() => {
              setUser(data);
              setViewModal(true);
            }}
            style={{
              color: "grey",
              cursor: "pointer",
              fontSize: 25,
              marginRight: 10,
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div id="users" style={{ padding: 30 }}>
      <div className="users-wrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Jobs</h1>
          <div>
            <Button onClick={() => downloadExcelFile(data)} type="primary">
              Download
            </Button>
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div>
                <p>Search by Student</p>
                <Input
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    searchByName(e.target.value);
                  }}
                  placeholder="Search By Student"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div>
                <p>Search by Trainer</p>
                <Input
                  value={search1}
                  onChange={(e) => {
                    setSearch1(e.target.value);
                    search1ByName(e.target.value);
                  }}
                  placeholder="Search By Trainer"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <p>Search by Date</p>
              <RangePicker
                style={{ width: "100%" }}
                onChange={(e, d) => searchByDate(d)}
              />
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: 30 }}>
          <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={data}
          />
        </div>
      </div>
      <Modal
        footer={false}
        title="View Job"
        okText="Done"
        visible={viewModal}
        onCancel={() => setViewModal(false)}
        onOk={() => {
          setViewModal(false);
        }}
      >
        <div>
          {/* <center><Image style={{ width: 100 , height: 100, marginBottom: 30}} src={userData?.imagePath} /></center> */}
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Date</p>
              </b>
              <p>{userData?.date?.substring(0, 10)}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Time</p>
              </b>
              <p>{userData?.time}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Trainer</p>
              </b>
              <p>
                {userData?.trainer?.firstName} {userData?.trainer?.lastName}
              </p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Student</p>
              </b>
              <p>
                {userData?.student?.firstName} {userData?.student?.lastName}
              </p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Status</p>
              </b>
              <p>{userData?.status}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Course (English)</p>
              </b>
              <p>{userData?.course?.nameEnglish}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Course (Arabic)</p>
              </b>
              <p>{userData?.course?.nameArabic}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}></Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default Jobs;
