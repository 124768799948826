import React, { useState, useEffect } from 'react'
import { Button, Form, Row, Col, Image } from 'antd'
import UploadImage from '../../Components/UploadImage'
import FormInput from '../../Components/FormInput'
import SelectBox from '../../Components/SelectBox'
import { countries } from '../../constants'
import { post, patch, get } from '../../services/RestService'
import { openNotification, useQueryParams } from '../../helpers'
import { useHistory } from 'react-router'
import { useMediaQuery } from 'react-responsive'

const gender = [
  { value: 'Male', title: 'Male'},
  { value: 'Female', title: 'Female'},
  { value: 'other', title: 'Other'}
]

const role = [
  { value: 'read', title: 'Read'},
  { value: 'read,write', title: 'Read, Write'},
  { value: 'read,write,update,delete', title: 'Modify'}
]

const UserForm = () => {
  const [ image, setImage ] = useState()
  const [imagePath, setImagePath] = useState()
  const history = useHistory()
  const query = useQueryParams()
  const [ userData, setUserData] = useState()
  const method = query.get('type')
  const id = query.get('id')
  const edit  = method === 'edit'
  const view  = method === 'view'
  const isMobileScreen = useMediaQuery({ query: '(max-width: 680px)' })
  const [form] = Form.useForm()

  const onSubmit = (values) => {

    const t = localStorage.getItem('token')
    const token = `Bearer ${t}`
    var options = {
      headers: { 
        'Authorization': token
      }
    }
    const formdata = new FormData();
  
    formdata.append("userImage", image ? image : imagePath);
    formdata.append("firstName", values.firstName);
    formdata.append("lastName", values.lastName);
    formdata.append("gender", values.gender);
    formdata.append("residenceCountry", values.residence);
    formdata.append("phoneNo", values.phoneNo);
    formdata.append("nationality", values.nationality);
    formdata.append("role", values.role);
    formdata.append("email", values.email);

    post('/admin/createUser', formdata, options).then((res)=> {
      if(res.status) {
        openNotification(res.message)
        history.push('users')
      } else {
        openNotification(res.error, 'error')
      }
    }).catch((err) => {
      openNotification(err.error, 'error')
    })
  }

  const passwordReset = (values) => {
    const t = localStorage.getItem('token')
      const token = `Bearer ${t}`
      var options = {
        headers: { 
          'Authorization': token
        }
      }

      const data = values
      data.userId = id
      
      patch('/admin/resetUserPassword',data , options).then((data) => {
        console.log(data)
        if (data.error) {
          openNotification(data.error)
        }
        else {
          openNotification(data.message, 'error')
        }
      }).catch((err) => {
        openNotification(err.error, 'error')
      })
  }

  const getData = () => {
    const t = localStorage.getItem('token')
      const token = `Bearer ${t}`
      var options = {
        headers: { 
          'Authorization': token
        }
      }
    get(`/admin/getUser?userId=${id}`, options).then((res)=> {
      console.log(res)
      setUserData(res)
      setImagePath(res.imagePath)
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    if(edit || view)
      getData()
  }, [edit, view])

  const onEdit = (values) => {

    const t = localStorage.getItem('token')
    const token = `Bearer ${t}`
    var options = {
      headers: { 
        'Authorization': token
      }
    }
    const formdata = new FormData();
    formdata.append("userId", id);
    formdata.append("userImage", image ? image : imagePath);
    formdata.append("firstName", values.firstName);
    formdata.append("lastName", values.lastName);
    formdata.append("gender", values.gender);
    formdata.append("residenceCountry", values.residence);
    formdata.append("phoneNo", values.phoneNo);
    formdata.append("nationality", values.nationality);
    formdata.append("role", values.role);
    formdata.append("email", values.email);

    patch('/admin/updateUser', formdata, options).then((res)=> {
      if(res.status) {
        openNotification(res.message)
        history.push('users')
      } else {
        openNotification(res.error, 'error')
      }
    }).catch((err) => {
      openNotification(err.error, 'error')
    })
  }

  return (
    <div style={{ padding: isMobileScreen ? 10 : 50}}>
      <div style={{backgroundColor: 'white', padding: 20}}>
        <h1><span style={{ marginRight: 5, cursor: 'pointer'}} onClick={()=> history.push('users')}>Users /</span>{edit ? 'Edit' : view ? 'View' : 'Create'} User</h1>

        {(image || imagePath) && <div style={{ textAlign: 'center'}}>
            <Image src={image ? URL.createObjectURL(image) : imagePath} style={{ width: 100, heigt: 100, objectFit: 'contain', }} />
          </div>}

        {!view && <div style={{ marginTop: 50}}>
          <p>Upload Image</p>
          <UploadImage image={image} setImage={setImage} />
        </div>}

        {((edit && userData) || (view && userData) || !edit ) && <div style={{ marginTop: 20}}>
          <Form scrollToFirstError form={form} onFinish={edit ? onEdit : onSubmit} initialValues={userData}>

            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <p>First Name</p>
                <FormInput name='firstName'/>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <p>Last Name</p>
                <FormInput name='lastName'/>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <p>Email</p>
                <FormInput name='email'/>
              </Col>
            </Row>

            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <p>Residence</p>
                <SelectBox name='residence' country data={countries} />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <p>Phone</p>
                <FormInput name='phoneNo'/>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <p>Gender</p>
                <SelectBox name='gender' data={gender} />
              </Col>
            </Row>

            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <p>Country</p>
                <SelectBox name='nationality' country data={countries} />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <p>Role</p>
                <SelectBox name='role' data={role} />
              </Col>
            </Row>
              {!view && <Button type='primary' htmlType='submit' >{edit ? 'Edit' : 'Create'} User</Button>}
          </Form>
        </div>}
      </div>

      {!view && edit && <div>
        <div style={{backgroundColor: 'white', padding: 20, marginTop: 30}}>
          <h1>Reset Password</h1>
          <Form onFinish={passwordReset}>

            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <p>Current Password</p>
                <FormInput name='currentPassword' type='password' />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <p>New Password</p>
                <FormInput name='newPassword' type='password' />
              </Col>
            </Row>
            <Button type='primary' htmlType='submit' >Reset Password</Button>
          </Form>
        </div>
      </div>}
    </div>
  )
}

export default UserForm
