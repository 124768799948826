import React, { useState, useEffect } from "react";
import { Button, Row, Col, Image, Spin } from "antd";
import FormInput from "../../Components/FormInput";
import { post, patch } from "../../services/RestService";
import { openNotification, options } from "../../helpers";
import UploadImage from "../../Components/UploadImage";
import UploadFile from "../../Components/UploadFile";
import SelectBox from "../../Components/SelectBox";
import { Recorder } from "react-voice-recorder";
import "react-voice-recorder/dist/index.css";
import "./styles.css";

const CreateModal = ({ method, testId, closeModal, getTableData, data }) => {
  const [userData, setUserData] = useState();
  const [recordState, setRecordState] = useState(null);
  const [audioLoading, setAudioLoading] = useState(false)
  const [image, setImage] = useState();
  const [imagePath, setImagePath] = useState();
  const [file, setFile] = useState();
  const edit = method === "edit";
  const view = method === "view";
  const create = method === "create";
  const [audioDetails, setAudioDetails] = useState({
    url: null,
    blob: null,
    chunks: null,
    duration: {
      h: 0,
      m: 0,
      s: 0,
    },
  });
  const handleReset = () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    };
    setAudioDetails(reset);
  };
  const [questions, setQuestions] = useState([
    {
      test: testId,
      questionImage: "",
      questionAudio: "",
      question: "",
      answer: "",
      optionA: "",
      optionB: "",
      optionC: "",
      optionD: "",
    },
  ]);

  // handle location input change
  const handleInputChange = (e, name, index) => {
    const value = e;
    const list = [...questions];
    list[index][name] = value;
    setQuestions(list);
  };

  //handle location click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...questions];
    list.splice(index, 1);
    setQuestions(list);
  };

  //handle location click event of the Add button
  const handleAddClick = () => {
    setQuestions([
      ...questions,
      {
        test: testId,
        questionImage: "",
        questionAudio: "",
        question: "",
        answer: "",
        optionA: "",
        optionB: "",
        optionC: "",
        optionD: "",
      },
    ]);
  };

  const uploadFile = (file, index) => {
    setAudioLoading(true)
    const formdata = new FormData();
    formdata.append("questionAudio", file);
    post("/admin/fileUpload", formdata, options)
      .then((res) => {
        handleInputChange(res.path, "questionAudio", index);
        setAudioLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setAudioLoading(false)
      });
  };

  const uploadImage = (file, index) => {
    const formdata = new FormData();
    formdata.append("questionImage", file);
    post("/admin/fileUpload", formdata, options)
      .then((res) => {
        handleInputChange(res.path, "questionImage", index);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = (values) => {
    const data = questions;

    post("/admin/createQuestion", data, options)
      .then((res) => {
        if (res.status) {
          openNotification(res?.message);
          closeModal();
          getTableData();
        } else {
          openNotification(res?.error, 'error');
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(err?.error, 'error');
      });
  };

  useEffect(() => {
    if (edit || view) setUserData(data);
    setImagePath(data.questionImage);
    setFile(data.questionAudio);
  }, [edit, view, setUserData, data]);

  const onEdit = (values) => {
    const formdata = new FormData();
    formdata.append("testId", testId);
    formdata.append("questionId", data._id);
    formdata.append("questionImage", image ? image : imagePath);
    formdata.append("questionAudio", file);
    Object.keys(values).forEach((key) => formdata.append(key, values[key]));

    patch("/admin/updateQuestion", formdata, options)
      .then((res) => {
        if (res.status) {
          openNotification(res.message);
          closeModal();
          getTableData();
        } else {
          openNotification(res.message, 'error');
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(err.error, 'error');
      });
  };

  const answerSelect = [
    { value: "a", title: "A" },
    { value: "b", title: "B" },
    { value: "c", title: "C" },
    { value: "d", title: "D" },
  ];

  return (
    <div>
      <div>
        {((edit && userData) || (view && userData) || create) && (
          <div style={{ marginTop: 10 }}>
            {questions.length &&
              questions.map((qes, index) => (
                <>
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {qes.questionImage && (
                        <Image
                          style={{
                            width: 100,
                            height: 100,
                            objectFit: "contain",
                          }}
                          src={qes.questionImage}
                        />
                      )}
                      
                    </div>
                    <Row gutter={[10, 10]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <p>Question Image</p>
                        <UploadImage
                          image={qes.questionImage}
                          imageIndex={index}
                          test
                          setImage={uploadImage}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <p>Question Audio File</p>
                        <Row gutter={[10, 10]}>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <UploadFile
                              file={qes.questionAudio}
                              fileIndex={index}
                              setFile={uploadFile}
                              audio
                              test
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {audioLoading &&  <div style={{ marginTop: 10}}>
                                      <Spin /> 
                                    </div>}
                  {qes.questionAudio && (
                    <div style={{ marginTop: 10}}>
                      {<audio
                          src={qes.questionAudio}
                          style={{ width: "100%" }}
                          controls
                        />}
                    </div>
                  )}
                  <Recorder
                    record={true}
                    title={"New recording"}
                    hideHeader
                    audioURL={audioDetails.url}
                    handleAudioStop={(data) => setAudioDetails(data)}
                    handleAudioUpload={(data) => {
                      uploadFile(data, index);
                      console.log(data);
                    }}
                    handleReset={() => handleReset()}
                  />
                  <Row gutter={[10, 10]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <p>Question</p>
                      <FormInput
                        name="question"
                        value={qes.question}
                        onChange={(e) =>
                          handleInputChange(e.target.value, "question", index)
                        }
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <p>Answer</p>
                      <SelectBox
                        value={qes.answer}
                        onChange={(e) => handleInputChange(e, "answer", index)}
                        data={answerSelect}
                        name="answer"
                      />
                    </Col>
                  </Row>

                  <Row gutter={[10, 10]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <p>Option A</p>
                      <FormInput
                        name="optionA"
                        value={qes.optionA}
                        onChange={(e) =>
                          handleInputChange(e.target.value, "optionA", index)
                        }
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <p>Option B</p>
                      <FormInput
                        name="optionB"
                        value={qes.optionA}
                        onChange={(e) =>
                          handleInputChange(e.target.value, "optionB", index)
                        }
                      />
                    </Col>
                  </Row>

                  <Row gutter={[10, 10]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <p>Option C</p>
                      <FormInput
                        name="optionC"
                        value={qes.optionA}
                        onChange={(e) =>
                          handleInputChange(e.target.value, "optionC", index)
                        }
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <p>Option D</p>
                      <FormInput
                        name="optionD"
                        value={qes.optionA}
                        onChange={(e) =>
                          handleInputChange(e.target.value, "optionD", index)
                        }
                      />
                    </Col>
                  </Row>

                  <div style={{ textAlign: "right" }}>
                    <Button
                      onClick={() => handleRemoveClick(index)}
                      type="primary"
                      htmlType="button"
                    >
                      Remove
                    </Button>
                  </div>
                </>
              ))}

            <div>
              {!view && (
                <Button
                  style={{ marginTop: 10, marginBottom: 10 }}
                  onClick={() => handleAddClick()}
                  type="primary"
                  htmlType="button"
                >
                  Add more questions
                </Button>
              )}
            </div>

            <div style={{ textAlign: "right" }}>
              {!view && (
                <Button
                  onClick={() => (edit ? onEdit() : onSubmit())}
                  type="primary"
                  htmlType="button"
                >
                  {edit ? "Save" : "Save"}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateModal;
