import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Spin, Table } from "antd";
import FormInput from "../../Components/FormInput";
import { get, post, patch } from "../../services/RestService";
import { openNotification, options } from "../../helpers";
import SelectBox from "../../Components/SelectBox";

const FormModal = ({ method, closeModal, getTableData, data }) => {
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  const [questionLoading, setQuestionLoading] = useState(true);
  const [questionData, setQuestionData] = useState([]);
  const [courses, setCourses] = useState([{ value: "", title: "" }]);
  const [form] = Form.useForm();
  const edit = method === "edit";
  const view = method === "view";
  const create = method === "create";

  useEffect(() => {
    const formdata = new FormData();
    formdata.append("testId", data._id);
    get(`/admin/getTestQuestions?testId=${data._id}`, options)
      .then((res) => {
        setQuestionData(res);
        setQuestionLoading(false);
        console.log(res);
      })
      .catch((err) => {
        setQuestionLoading(false);
        console.log(err);
      });
  }, []);

  const onSubmit = (values) => {
    const formdata = new FormData();
    Object.keys(values).forEach((key) => formdata.append(key, values[key]));

    post("/admin/createTest", formdata, options)
      .then((res) => {
        console.log(res);
        if (res.status) {
          openNotification(res.message);
          closeModal();
          getTableData();
        } else {
          openNotification(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(err.error, 'error');
      });
  };

  useEffect(() => {
    get("/admin/getCourses", options)
      .then((data) => {
        const cdata = [];
        data?.map((d) =>
          cdata.push({
            value: d?._id,
            title: `${d?.nameEnglish} ${d?.nameArabic}`,
          })
        );
        setCourses(cdata);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (edit || view) setUserData(data);
    form.setFieldsValue({ courseId: data?.course?._id });
  }, [edit, view, setUserData, data]);

  const onEdit = (values) => {
    const formdata = new FormData();
    formdata.append("testId", data._id);
    Object.keys(values).forEach((key) => formdata.append(key, values[key]));

    patch("/admin/updateTest", formdata, options)
      .then((res) => {
        console.log(res);
        if (res.status) {
          openNotification(res.message);
          closeModal();
          getTableData();
        } else {
          openNotification(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
        openNotification(err.error, 'error');
      });
  };

  const testSelect = [
    { value: "basic", title: "Basic" },
    { value: "advance", title: "Advance" },
  ];

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      render: (_, data) => <p>{data?.question} </p>,
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      render: (_, data) => <p>{data?.answer} </p>,
    },
    {
      title: "Option A",
      dataIndex: "optionA",
      key: "optionA",
      render: (_, data) => <p>{data?.optionA}</p>,
    },
    {
      title: "Option B",
      dataIndex: "optionB",
      key: "optionB",
      render: (_, data) => <p>{data?.optionB}</p>,
    },
    {
      title: "Option C",
      dataIndex: "optionC",
      key: "optionC",
      render: (_, data) => <p>{data?.optionC}</p>,
    },
    {
      title: "Option D",
      dataIndex: "optionD",
      key: "optionD",
      render: (_, data) => <p>{data?.optionD}</p>,
    },
  ];

  return loading ? (
    <Spin />
  ) : (
    <div>
      <div>
        {((edit && userData) || (view && userData) || create) && (
          <div style={{ marginTop: 10 }}>
            <Form
              form={form}
              onFinish={edit ? onEdit : onSubmit}
              initialValues={userData}
            >
              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p>Select Course</p>
                  <SelectBox data={courses} name="courseId" />
                </Col>
              </Row>

              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p>Title</p>
                  <FormInput name="title" />
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p>Test Type</p>
                  <SelectBox data={testSelect} name="testType" />
                </Col>
              </Row>
              {/* <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <p>Questions Limit</p>
                <FormInput name='questionsLimit'/>
              </Col>
            </Row> */}
              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p>Passing Percentage</p>
                  <FormInput name="passingPercentage" addonAfter="%" />
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p>Test Duration</p>
                  <FormInput name="testDuration" addonAfter="Minutes"/>
                </Col>
                {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <p>Test Price</p>
                <FormInput name='testPrice' />
              </Col> */}
              </Row>
              {!view && (
                <Button type="primary" htmlType="submit">
                  {edit ? "Save" : "Create"}
                </Button>
              )}
            </Form>
            {!create && (
              <Table
                style={{ marginTop: 30 }}
                scroll={{ x: true }}
                loading={questionLoading}
                dataSource={questionData}
                columns={columns}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FormModal;
