import React, { useState, useEffect } from 'react'
import { Row, Col, Input, Table, Modal } from 'antd';
import { get, deleteService } from '../../services/RestService'
import { DeleteFilled, EyeFilled, EditFilled  } from '@ant-design/icons';
import { Button } from 'antd'
import { downloadExcelFile, openNotification } from '../../helpers';
import FormModal from './FormModal';
import { useHistory } from 'react-router';

const Test = () => {
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const searchData =  (data && data.filter((sd) => sd?.title.toString().toLowerCase().includes(search))) || []
  const [viewModal, setViewModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [method, setMethod] = useState()
  const history = useHistory()

  const getData = () => {
    setLoading(true)
    const t = localStorage.getItem('token')
      const token = `Bearer ${t}`
      var options = {
        headers: { 
          'Authorization': token
        }
      }
      get('/admin/getTests',options).then((data) => {
        setData(data)
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        console.log(err)
      })
  };

  const deleteUser = (id) => {
    setLoading(true)
    const data ={
      testId : id,
    }
    deleteService(`/admin/deleteTest`,data).then((data) => {
      console.log(data)
      if (data.status) {
        openNotification('Test Deleted Successfully')
        getData()
      }
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
      console.log(err)
    })
  };

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (_, data) => <p>{data?.title} </p>
    },
    {
      title: 'Type',
      dataIndex: 'testType',
      key: 'testType',
      render: (_, data) => <p>{data?.testType} </p>
    },
    {
      title: 'Duration',
      dataIndex: 'testDuration',
      key: 'testDuration',
      render : (_, data) => <p>{data.testDuration && data.testDuration}</p>
    },
    {
      title: 'Passing_Percentage',
      dataIndex: 'passingPercentage',
      key: 'passingPercentage',
      render : (_, data) => <p>{data.passingPercentage && data.passingPercentage}</p>
    },
    {
      title: 'Course',
      dataIndex: 'course',
      key: 'course',
      render : (_, data) => <p>{data?.course?.nameEnglish}</p>
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render : (_, data) => <div style={{ display: 'flex', alignItems: 'center'}}>
                                <EyeFilled onClick={()=> {setModalData(data); setMethod('view'); setViewModal(true)}} style={{ color: 'grey' , cursor: 'pointer', fontSize: 25, marginRight: 10}} />
                                <EditFilled onClick={()=> {setModalData(data); setMethod('edit'); setViewModal(true)}} style={{ color: 'grey' , cursor: 'pointer', fontSize: 25, marginRight: 10}} />
                                <DeleteFilled onClick={()=> {deleteUser(data._id)}} style={{ color: 'grey' , cursor: 'pointer', fontSize: 25}} />
                                <Button style={{ marginLeft:10}} type='primary' onClick={()=> {history.push(`questions?id=${data._id}`)}} >Questions</Button>
                            </div>
    }
  ]

  return (
    <div id='users' style={{ padding: 30}}>
      <div className='users-wrapper'>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <h1>Test</h1>
          <div>
            <Button type='primary' onClick={()=> downloadExcelFile(data, 'tests')}>Download</Button>
            <Button onClick={()=>  {setMethod('create'); setViewModal(true)}} style={{ marginLeft:30}} type='primary'>Create Test</Button>
          </div>
        </div>

        <div style={{ marginTop: 30}}>
        <Row gutter={[10,10]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div>
              <p>Search by Title</p>
              <Input value={search} onChange={(e)=> setSearch(e.target.value)} placeholder='Search By Title' />
            </div>
          </Col>
        </Row>
        </div>

        <div style={{ marginTop: 30 }}>
          <Table scroll={{ x: true }} loading={loading} columns={columns} dataSource={searchData.reverse()} />
        </div>
      </div>
      <Modal width={800} destroyOnClose footer={false} title='Test' okText='Done' visible={viewModal} onCancel={()=> setViewModal(false)} onOk={()=> {setViewModal(false)}}>
        <FormModal closeModal={()=> setViewModal(false)} getTableData={getData} data={modalData} method={method} />
      </Modal>
    </div>
  )
}

export default Test
