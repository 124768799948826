import { LOGIN_ERROR, SET_CURRENT_USER } from './types';
import { post } from '../../services/RestService';

// Login - Get User Token
export const loginUser = (userData) => dispatch => {
  post('/admin/login', userData).then((res) => {
    console.log(res)
    const { admin_login_token } = res
    // Set token to ls
    localStorage.setItem('token', admin_login_token)
    localStorage.setItem('user', JSON.stringify(res))
    //set Data
    dispatch(setCurrentUser(res));
  }).catch((err) => {
    console.log(err)
    dispatch({
      type: LOGIN_ERROR,
      payload: err.error
    })
  })
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from localStorage
  localStorage.removeItem('token');
  // Remove auth header for future requests
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
