import React, { useState, useEffect } from 'react'
import { Row, Col, Input, Table, Switch, Modal, Image, DatePicker } from 'antd';
import { get, patch, deleteService } from '../../services/RestService'
import { DeleteFilled, EyeFilled  } from '@ant-design/icons';
import { Button } from 'antd'
import { downloadExcelFile, openNotification } from '../../helpers';
import FormTextarea from '../../Components/FormTextarea'

const {RangePicker } = DatePicker

const Students = () => {
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])
  const [dataFilter, setFilterData] = useState([])
  const [loading, setLoading] = useState(true)
  const [remarks, setRemarks] = useState('')
  const [viewModal, setViewModal] = useState(false)
  const [userId, setUserId] = useState()
  const [user, setUser] = useState({})
  const [viewUserModal, setViewUserModal] = useState(false)

  const searchByName = (name) => {
    if (name !== '') {
      console.log(name);
      const res = data.filter((sd) => {
        return (sd.user && sd.user.firstName && sd.user.firstName.toString().toLowerCase().includes(search)) || (sd.user && sd.user.email && sd.user.email.toString().toLowerCase().includes(search))
      });
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const searchById = (name) => {
    if (name !== '') {
      console.log(name);
      const res = data.filter((sd) => {
        return (sd?.studentId.toString().toLowerCase().includes(name)) 
      });
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const searchByDate = (dates) => {
    if (dates[0] !== '' && dates[1] !== '' ) {
      console.log(dates);
      const res = data.filter((sd) => {
        return sd?.user?.registrationTime.substring(0,10) >= dates[0] && sd?.user?.registrationTime.substring(0,10) <= dates[1]
      });
      console.log(res)
      setData(res);
    } else {
      setData(dataFilter);
    }
  };
  
  const getData = () => {
    setLoading(true)
    const t = localStorage.getItem('token')
      const token = `Bearer ${t}`
      var options = {
        headers: { 
          'Authorization': token
        }
      }
      get('/admin/getStudents',options).then((data) => {
        console.log(data)
        setData(data.reverse())
        setFilterData(data.reverse())
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        console.log(err)
      })
  };

  const updateStatus = (id, checked) => {
    setLoading(true)
    const t = localStorage.getItem('token')
      const token = `Bearer ${t}`
      var options = {
        headers: { 
          'Authorization': token
        }
      }

      const data ={
        studentId : id
      }
      patch('/admin/activeStudentAccount',data , options).then((data) => {
        console.log(data)
       
          openNotification('Status Changed Successfully')
          getData()
        
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        console.log(err)
      })
  };

  const deleteUser = (id) => {
    setLoading(true)
    const formdata = new FormData()
    formdata.append('studentId', id)
    
    deleteService(`/admin/deleteStudent`,formdata ).then((data) => {
      console.log(data)
      
        openNotification('Student Deleted Successfully')
        getData()
      
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
      console.log(err)
    })
  };

  useEffect(() => {
    getData()
  }, [])

  const sendRemarks = () => {

    setLoading(true)
    const token = localStorage.getItem('token')
    var options = {
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    }
    const data = {
      studentId: userId,
      remarks: remarks
    }
    
    patch(`/admin/assignStudentRemarks`,data, options).then((res) => {
      setLoading(false)
      console.log(res)
      openNotification('Remarks Sent Successfully')
      setRemarks('')
      setUserId()
      getData() 
    }).catch((err) => {
      setLoading(false)
      console.log(err.message)
    })

  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: '20%',
      render: (_, data) => <p>{`st-${data.studentId}`}</p>
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (_, data) => <Image style={{ width: 50, height: 50, objectFit: 'contain'}} src={data.user && data.user.imagePath} />
    },
    {
      title: 'Registration_Date',
      dataIndex: 'user.registrationTime',
      key: 'user.registrationTime',
      render: (_, data) => <div>{data.user && data.user.registrationTime && data.user.registrationTime.substring(0,10)}</div>
    },
    {
      title: 'First_Name',
      dataIndex: 'user.firstName',
      key: 'user.firstName',
      render: (_, data) => <div>{data.user && data.user.firstName && data.user.firstName}</div>
    },
    {
      title: 'Last_Name',
      dataIndex: 'user.lastName',
      key: 'user.lastName',
      render: (_, data) => <div>{data.user && data.user.lastName && data.user.lastName}</div>
    },
    {
      title: 'Email',
      dataIndex: 'user.email',
      key: 'user.email',
      render: (_, data) => <div>{data.user && data.user.email && data.user.email}</div>
    },
    {
      title: 'Phone_No',
      dataIndex: 'user.phoneNo',
      key: 'user.phoneNo',
      render: (_, data) => <div>{data.user && data.user.phoneNo && data.user.phoneNo}</div>
    },
    {
      title: 'Nationality',
      dataIndex: 'user.nationality',
      key: 'user.nationality',
      render: (_, data) => <div>{data.user && data.user.nationality && data.user.nationality}</div>
    },
    {
      title: 'Residence_Country',
      dataIndex: 'user.residenceCountry',
      key: 'user.residenceCountry',
      render: (_, data) => <div>{data.user && data.user.residenceCountry && data.user.residenceCountry}</div>
    },
    {
      title: 'Status',
      dataIndex: 'accountActive',
      key: 'accountActive',
      render : (_, data) => <Switch checked={data.accountActive} onChange={(e) => updateStatus(data._id, e)} />,
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      onFilter: (value, data) => data.accountActive === value
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render : (_, data) => <div style={{ display: 'flex', alignItems: 'center'}}>
                                <EyeFilled onClick={()=> {setUser(data); setViewUserModal(true)}} style={{ color: 'grey' , cursor: 'pointer', fontSize: 25, marginRight: 10}} />
                                <DeleteFilled onClick={()=> {deleteUser(data && data._id)}} style={{ color: 'grey' , cursor: 'pointer', fontSize: 25, marginRight: 10}} />
                                <Button onClick={()=> {setUserId(data && data._id);setViewModal(true)}} type='primary' size='small' >Remarks</Button>
                            </div>
    }
  ]

  const downloadData = []
  data && data.length && data.map((d) => d.user && downloadData.push({...d.user, ...d.accountActive}))

  return (
    <div id='users' style={{ padding: 30}}>
      <div className='users-wrapper'>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <h1>Students</h1>
          <div>
            <Button type='primary' onClick={()=> downloadExcelFile(downloadData, 'students')}>Download</Button>
          </div>
        </div>

        <div style={{ marginTop: 30}}>
        <Row gutter={[10,10]}>
          {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div>
              <p>Filter By Status</p>
              <>
                <Select defaultValue="Select Option" style={{ width: '100%' }} onChange={handleChange}>
                  <Option value="All">All</Option>
                  <Option value="Active">Active</Option>
                  <Option value="Inactive">Inactive</Option>
                </Select>
              </>
            </div>
          </Col> */}
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div>
              <p>Search By Id</p>
              <Input  onChange={(e)=> {searchById(e.target.value)}} placeholder='Search By Id' />
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div>
              <p>Search By Name or Email</p>
              <Input value={search} onChange={(e)=> {setSearch(e.target.value);searchByName(e.target.value)}} placeholder='Search By Name or Email' />
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <p>Search by Date</p>
            <RangePicker style={{ width: '100%'}} onChange={(e, d) => searchByDate(d)} />
          </Col>
        </Row>
        </div>

        <div style={{ marginTop: 30 }}>
          <Table scroll={{ x: true }} loading={loading} columns={columns} dataSource={data} />
        </div>
      </div>

      <Modal title='Add Remarks' okText='Send' visible={viewModal} onCancel={()=> setViewModal(false)} onOk={()=> {setViewModal(false) ; sendRemarks()}}>
        <FormTextarea value={remarks} onChange={(e)=> setRemarks(e.target.value)} label='Remarks' />
      </Modal>
      <Modal footer={false} title='View Student' okText='Done' visible={viewUserModal} onCancel={()=> setViewUserModal(false)} onOk={()=> {setViewUserModal(false)}}>
        <div>
          <center><Image style={{ width: 100 , height: 100, marginBottom: 30}} src={user?.user?.imagePath} /></center>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b><p>Student Id</p></b>
              <p>{'st-'}{user?.studentId}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b><p>Registration Date</p></b>
              <p>{user?.user?.registrationTime.substring(0, 10)}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b><p>First Name</p></b>
              <p>{user?.user?.firstName}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b><p>Last Name</p></b>
              <p>{user?.user?.lastName}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b><p>Email</p></b>
              <p>{user?.user?.email}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b><p>Phone</p></b>
              <p>{user?.user?.phoneNo}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b><p>Status</p></b>
              <p>{user?.accountActive ? 'Active' : 'Non-Active'}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b><p>Gender</p></b>
              <p>{user?.user?.gender}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b><p>Residence</p></b>
              <p>{user?.user?.residenceCountry}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b><p>Country</p></b>
              <p>{user?.user?.nationality}</p>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

export default Students
