import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Spin, Button, Upload, Divider, Image } from 'antd'
import FormInput from '../../Components/FormInput'
import { get, patch } from '../../services/RestService'
import { options, openNotification } from '../../helpers'
import FormTextarea from '../../Components/FormTextarea'

const About = () => {

  const [loading, setLoading] = useState()
  const [data, setData] = useState()
  const [companyImages, setCompanyImages] = useState([])
  const [missionImages, setMissionImages] = useState([])
  const [visionImages, setVisionImages] = useState([])
  const [clientImages, setClientImages] = useState([])

  const [companyImagesPath, setCompanyImagesPath] = useState([])
  const [missionImagesPath, setMissionImagesPath] = useState([])
  const [visionImagesPath, setVisionImagesPath] = useState([])
  const [clientImagesPath, setClientImagesPath] = useState([])

  

  const getData = () => {
    setLoading(true)
      get('/admin/getAbout',options).then((data) => {
        setData(data)
        setCompanyImagesPath(data?.companyImages)
       
        setVisionImagesPath( data?.visionImages)
        
        setMissionImagesPath(data?.missionImages)
       
        setClientImagesPath( data?.clientImages)
       
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  const dummyRequest = ({ _, onSuccess }) => {
    setTimeout(() => { onSuccess('ok') }, 0)
  }

  const cFileSelected  = ({ file, fileList: newFileList }) => {
    setCompanyImages(newFileList);
  }

  const mFileSelected  = ({ file, fileList: newFileList }) => {
    setMissionImages(newFileList);
  }

  const vFileSelected  = ({ file, fileList: newFileList }) => {
    setVisionImages(newFileList);
  }

  const clFileSelected  = ({ file, fileList: newFileList }) => {
    setClientImages(newFileList);
    console.log(newFileList)
  }


  const updateSetting = (values) => {
    console.log(values)
  
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    formData.append('aboutId', data._id)
    formData.append('companyImages', JSON.stringify(companyImagesPath) )
    if ( companyImages ) {
			companyImages.forEach(file => {
        formData.append( 'companyImages', file.originFileObj)
      })
		}
    formData.append('visionImages', JSON.stringify(visionImagesPath) )
    if ( visionImages ) {
			visionImages.forEach(file => {
        formData.append( 'visionImages', file.originFileObj)
      })
		}
    formData.append('missionImages', JSON.stringify(missionImagesPath) )
    if ( missionImages ) {
			missionImages.forEach(file => {
        formData.append( 'missionImages', file.originFileObj)
      })
		}
    formData.append('clientImages', JSON.stringify(clientImagesPath) )
    if ( clientImages ) {
			clientImages.forEach(file => {
        formData.append( 'clientImages', file.originFileObj)
      })
		}
    
    patch('/admin/updateAbout', formData, options).then((res)=> {
      console.log(res)
      if(res.status) {
        openNotification(res.message)
        setClientImages([])
        setCompanyImages([])
        setVisionImages([])
        setMissionImages([])
        getData()
      } else {
        openNotification(res.error, 'error')
      }
    }).catch((err) => {
      console.log(err)
      openNotification(err?.error, 'error')
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const removePic = (dt, type) => {
    if(type === 'company') {
      const arr = companyImagesPath.filter((data) => data !== dt)
      setCompanyImagesPath(arr)
    }
    if(type === 'mission') {
      const arr = missionImagesPath.filter((data) => data !== dt)
      setMissionImagesPath(arr)
    }
    if(type === 'vision') {
      const arr = visionImagesPath.filter((data) => data !== dt)
      setVisionImagesPath(arr)
    }
    if(type === 'client') {
      const arr = clientImagesPath.filter((data) => data !== dt)
      setClientImagesPath(arr)
    }
    
  }

  return ( loading ? <Spin style={{ marginTop: 20}} /> :
    <div style={{ padding: 30}}>
      <div style={{ backgroundColor: 'white', padding: 20}}>
        <h1>
          About Page Details
        </h1>

        <Divider />

        <h1>Company Profile</h1>
        
        <div style={{ marginTop: 20}}>
          <h1>Upload Company Images</h1>
          <div style={{display: 'flex', marginBottom: 10}}>
            {companyImagesPath.map((dt)=> (
              <div style={{ display: 'flex', marginRight: 10, flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Image src={dt} style={{ width: 100, height: 100, objectFit: 'contain'}} />
                <Button style={{ marginTop: 10}} onClick={()=> removePic(dt, 'company')} small type='primary'>Remove</Button>
              </div>
            ))}
          </div>
          <Upload
            customRequest={dummyRequest}
            listType="picture-card"
            fileList={companyImages}
            onChange={cFileSelected}
          >
            {'+ Upload'}
           </Upload>
        </div>

        <div style={{ marginTop: 20}}>
          <Form initialValues={data} onFinish={updateSetting}>

          <Row gutter={[10, 10]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div style={{ border: '1px solid lightgrey', padding: 20}}>
                  <h1 style={{ textAlign: 'center' }}>English</h1>
                  <h3>English Heading</h3>
                  <FormInput name='companyHeadingEnglish' />
                  <h3>English Content</h3>
                  <FormTextarea name='companyContentEnglish' />
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: 20}} gutter={[10, 10]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div style={{ border: '1px solid lightgrey', padding: 20}}>
                  <h1 style={{ textAlign: 'center' }}>Arabic</h1>
                  <h3>Arabic Heading</h3>
                  <FormInput name='companyHeadingArabic' />
                  <h3>Arabic Content</h3>
                  <FormTextarea name='companyContentArabic' />
                </div>
              </Col>
            </Row>

            
            
            <Divider />
              <h1>Our Mission</h1>

              <div style={{ marginTop: 20}}>
                <h1>Upload Mission Images</h1>
                <div style={{display: 'flex', marginBottom: 10}}>
                  {missionImagesPath.map((dt)=> (
                    <div style={{ display: 'flex', marginRight: 10, flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                      <Image src={dt} style={{ width: 100, height: 100, objectFit: 'contain'}} />
                      <Button style={{ marginTop: 10}} onClick={()=> removePic(dt, 'mission')} small type='primary'>Remove</Button>
                    </div>
                  ))}
                </div>
                <Upload
                  customRequest={dummyRequest}
                  listType="picture-card"
                  fileList={missionImages}
                  onChange={mFileSelected}
                >
                  {'+ Upload'}
                 </Upload>
              </div>

              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div style={{ border: '1px solid lightgrey', padding: 20}}>
                    <h1 style={{ textAlign: 'center' }}>English</h1>
                    <h3>English Heading</h3>
                    <FormInput name='missionHeadingEnglish' />
                    <h3>English Content</h3>
                    <FormTextarea name='missionContentEnglish' />
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: 20}} gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div style={{ border: '1px solid lightgrey', padding: 20}}>
                    <h1 style={{ textAlign: 'center' }}>Arabic</h1>
                    <h3>Arabic Heading</h3>
                    <FormInput name='missionHeadingArabic' />
                    <h3>Arabic Content</h3>
                    <FormTextarea name='missionContentArabic' />
                  </div>
                </Col>
              </Row>


              <Divider />
              <h1>Our Vision</h1>

              <div style={{ marginTop: 20}}>
                <h1>Upload Vision Images</h1>
                <div style={{display: 'flex', marginBottom: 10}}>
                  {visionImagesPath.map((dt)=> (
                    <div style={{ display: 'flex', marginRight: 10, flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                      <Image src={dt} style={{ width: 100, height: 100, objectFit: 'contain'}} />
                      <Button style={{ marginTop: 10}} onClick={()=> removePic(dt, 'vision')} small type='primary'>Remove</Button>
                    </div>
                  ))}
                </div>
                <Upload
                  customRequest={dummyRequest}
                  listType="picture-card"
                  fileList={visionImages}
                  onChange={vFileSelected}
                >
                  {'+ Upload'}
                 </Upload>
              </div>

              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div style={{ border: '1px solid lightgrey', padding: 20}}>
                    <h1 style={{ textAlign: 'center' }}>English</h1>
                    <h3>English Heading</h3>
                    <FormInput name='visionHeadingEnglish' />
                    <h3>English Content</h3>
                    <FormTextarea name='visionContentEnglish' />
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: 20}} gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div style={{ border: '1px solid lightgrey', padding: 20}}>
                    <h1 style={{ textAlign: 'center' }}>Arabic</h1>
                    <h3>Arabic Heading</h3>
                    <FormInput name='visionHeadingArabic' />
                    <h3>Arabic Content</h3>
                    <FormTextarea name='visionContentArabic' />
                  </div>
                </Col>
              </Row>

              <Divider />
              <h1>Our Clients</h1>

              <div style={{ marginTop: 20}}>
                <h1>Upload Clients Images</h1>
                <div style={{display: 'flex', marginBottom: 10}}>
                  {clientImagesPath.map((dt)=> (
                    <div style={{ display: 'flex', marginRight: 10, flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                      <Image src={dt} style={{ width: 100, height: 100, objectFit: 'contain'}} />
                      <Button style={{ marginTop: 10}} onClick={()=> removePic(dt, 'client')} small type='primary'>Remove</Button>
                    </div>
                  ))}
                </div>
                <Upload
                  customRequest={dummyRequest}
                  listType="picture-card"
                  fileList={clientImages}
                  onChange={clFileSelected}
                >
                  {'+ Upload'}
                 </Upload>
              </div>

              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div style={{ border: '1px solid lightgrey', padding: 20}}>
                    <h1 style={{ textAlign: 'center' }}>English</h1>
                    <h3>English Heading</h3>
                    <FormInput name='clientHeadingEnglish' />
                    <h3>English Content</h3>
                    <FormTextarea name='clientContentEnglish' />
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: 20}} gutter={[10, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div style={{ border: '1px solid lightgrey', padding: 20}}>
                    <h1 style={{ textAlign: 'center' }}>Arabic</h1>
                    <h3>Arabic Heading</h3>
                    <FormInput name='clientHeadingArabic' />
                    <h3>Arabic Content</h3>
                    <FormTextarea name='clientContentArabic' />
                  </div>
                </Col>
              </Row>

            
            
            <div style={{ marginTop: 30, textAlign: 'right'}}>
              <Button size='large' type='primary' htmlType='submit' >Save</Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default About
