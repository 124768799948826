import React, { useState, useEffect } from "react";
import { Row, Col, Input, Table, Modal, Tag, Select, DatePicker } from "antd";
import { get, patch } from "../../services/RestService";
import { EyeFilled } from "@ant-design/icons";
import { options } from "../../helpers";
import { Button } from "antd";
import moment from "moment";
import { useLocation } from "react-router-dom";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Notifications = () => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [dataFilter, setFilterData] = useState([]);
  const [userData, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [viewModal, setViewModal] = useState(false);
  const [scheduleData, setScheduleData] = useState();
  const location = useLocation();
  console.log({ userData });

  const searchByName = (name) => {
    if (name !== "") {
      console.log(name);
      const res = data.filter((sd) => {
        return sd?.receipt_email.toString().toLowerCase().includes(search);
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const searchByDate = (dates) => {
    if (dates[0] !== "" && dates[1] !== "") {
      const res = data.filter((sd) => {
        return (
          sd?.createdAt.substring(0, 10) >= dates[0] &&
          sd?.createdAt.substring(0, 10) <= dates[1]
        );
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const getData = () => {
    setLoading(true);
    get("/admin/getNotifications", options)
      .then((data) => {
        console.log(data);
        setData(data.reverse());
        setFilterData(data.reverse());
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getScheduleData = (id) => {
    setLoading(true);
    get(`/admin/getBookedTestsById?id=${id}`, options)
      .then((data) => {
        console.log(data);
        setScheduleData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (userData?.scheduleId) {
      getScheduleData(userData?.scheduleId);
    }
    return () => {
      setScheduleData();
    };
  }, [userData]);

  const readNotification = (id) => {
    const formData = new FormData();
    formData.append("notificationId", id);
    formData.append("status", "read");
    patch("/admin/updateNotifications", formData, options)
      .then((data) => {
        if (data.status) {
          getData();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    setUser(location.data);
    setViewModal(location.showModal);
  }, [location]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, data) => <p>{data?.title}</p>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, data) => (
        <p>{data.createdAt && data.createdAt.substring(0, 10)}</p>
      ),
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      render: (_, data) => (
        <p>
          {data?.from?.firstName} {data?.from?.lastName}
        </p>
      ),
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "from",
      render: (_, data) => <p>{data?.from?.userType}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, data) => (
        <Tag color={"volcano"}>{data?.status.toUpperCase()}</Tag>
      ),
      filters: [
        {
          text: "Read",
          value: "read",
        },
        {
          text: "UnRead",
          value: "unread",
        },
      ],
      onFilter: (value, data) => data.status === value,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, data) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <EyeFilled
            onClick={() => {
              readNotification(data?._id);
              setUser(data);
              setViewModal(true);
            }}
            style={{
              color: "grey",
              cursor: "pointer",
              fontSize: 25,
              marginRight: 10,
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div id="users" style={{ padding: 30 }}>
      <div className="users-wrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Notifications</h1>
          <div>
            <Button type="primary">Download</Button>
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div>
                <p>Search by Title</p>
                <Input
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    searchByName(e.target.value);
                  }}
                  placeholder="Search By Title"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <p>Search by Date</p>
              <RangePicker
                style={{ width: "100%" }}
                onChange={(e, d) => searchByDate(d)}
              />
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: 30 }}>
          <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={data}
          />
        </div>
      </div>
      <Modal
        footer={false}
        title="View Notification"
        destroyOnClose
        okText="Done"
        visible={viewModal}
        onCancel={() => setViewModal(false)}
        onOk={() => {
          setViewModal(false);
        }}
      >
        <div>
          {/* <center><Image style={{ width: 100 , height: 100, marginBottom: 30}} src={userData?.imagePath} /></center> */}
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Created At</p>
              </b>
              <p>{userData?.createdAt?.substring(0, 10)}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Title</p>
              </b>
              <p>{userData?.title}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>From</p>
              </b>
              <p>
                {userData?.from?.firstName} {userData?.from?.lastName}
              </p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>UserType</p>
              </b>
              <p>{userData?.from?.userType}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Status</p>
              </b>
              <p>{userData?.status}</p>
            </Col>
          </Row>
          {scheduleData && (
            <div>
              <b>
                <p>Driving Schedule Test Detail</p>
              </b>
              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <b>
                    <p>Date and Time</p>
                  </b>
                  <p>
                    {moment(scheduleData?.date).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <b>
                    <p>Test</p>
                  </b>
                  <p>{scheduleData?.testType?.testName}</p>
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <b>
                    <p>Email</p>
                  </b>
                  <p>{scheduleData?.student?.email}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <b>
                    <p>Phone</p>
                  </b>
                  <p>{scheduleData?.student?.phoneNo}</p>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Notifications;
