import React from 'react'
import { Form, Select } from 'antd'

const { Option } = Select

const SelectBox = ({ data, country,  value, defaultValue, name, rules, placeholder, type, className, title, onChange, disabled, addonBefore, addonAfter, reference, min, width }) => {
  const inputProps = {}
  if (type) inputProps.type = type || 'text'
  if (min) inputProps.min = min

  return (
    <Form.Item name={name} rules={rules}>
       <Select value={value} style={{ borderRadius: 10, width: width ? width : '100%'}} defaultValue={defaultValue ? defaultValue : "Select"} onChange={onChange} >
          {data.map((d,i)=> (
            <Option key={i} value={d.value}>{country ? d.value : d.title}</Option>
          ))}
        </Select>
    </Form.Item>
  )
}

export default SelectBox
