import React, { useEffect, useState } from 'react'
import './styles.scss'
import { Row, Col, Select, DatePicker } from 'antd';
import { CHART_TYPES, CHART_DATASET_OPTIONS, options } from '../../helpers';
import Charts from '../../Components/ChartsGroup/index';
import { UserOutlined, ReadFilled, UnorderedListOutlined } from '@ant-design/icons';
import StatCard from '../../Components/StatCard';
import EventDetail from '../../Components/Calendar';
import { get } from '../../services/RestService'
import moment from 'moment'

const { Option } = Select
const { RangePicker } = DatePicker
const chartTypes = CHART_TYPES

const Dashboard = () => {
  const [type, setType] = useState('bar')
  const [ttype, setTType] = useState('bar')
  const [stype, setSType] = useState('bar')
  const [users, setUsers] = useState([])
  const [trainers, setTrainers] = useState([])
  const [students, setStudents] = useState([])
  const [statData, setStatData] = useState({})
  const  d = new Date();
  d.setMonth(d.getMonth() - 1);
  const dataNow = new Date();
  const [startDate] = useState(d)
  const [endDate] = useState(dataNow)

  const dataSetOptions = {
    label: 'Users',
    ...CHART_DATASET_OPTIONS
  }

  const tdataSetOptions = {
    label: 'Trainers',
    ...CHART_DATASET_OPTIONS
  }

  const sdataSetOptions = {
    label: 'Students',
    ...CHART_DATASET_OPTIONS
  }

  const getData = () => {
    get('/admin/dashboard/get', options).then((res)=> {
      setStatData(res)
    }).catch((res)=> {
      console.log(res)
    })
  }


  const getUsers = (sDate, eDate) => {
    get(`/admin/dashboard/userStat/${sDate}/${eDate}`, options).then((res)=> {
      setUsers({
        labels: res?.stats?.map((c) => c.date),
        datasets: [
          {
            ...dataSetOptions,
            data: res?.stats?.map((c) => c.userCreated)
          }
        ]
      })

    }).catch((res)=> {
      console.log(res)
    })
  }

  const getTrainers = (sDate, eDate) => {
    get(`/admin/dashboard/trainerStat/${sDate}/${eDate}`, options).then((res)=> {
      setTrainers({
        labels: res?.stats?.map((c) => c.date),
        datasets: [
          {
            ...tdataSetOptions,
            data: res?.stats?.map((c) => c.trainerCreated)
          }
        ]
      })

    }).catch((res)=> {
      console.log(res)
    })
  }

  const getStudents = (sDate, eDate) => {
    get(`/admin/dashboard/StudentStat/${sDate}/${eDate}`, options).then((res)=> {
      setStudents({
        labels: res?.stats?.map((c) => c.date),
        datasets: [
          {
            ...sdataSetOptions,
            data: res?.stats?.map((c) => c.studentCreated)
          }
        ]
      })

    }).catch((res)=> {
      console.log(res)
    })
  }

  const getDasboardChart = (sDate, eDate) => {
    getUsers(sDate, eDate)
    getStudents(sDate, eDate)
    getTrainers(sDate, eDate)
  }
  

  useEffect(() => {
    getData()
    getUsers(startDate, endDate)
    getStudents(startDate, endDate)
    getTrainers(startDate, endDate)
  }, [])

  return (
    <div className='dashboard'>
      <Row gutter={[10,10]}>
       <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <StatCard icon={<UserOutlined />} title='Users' number={statData?.user} />
       </Col>
       <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <StatCard icon={<UnorderedListOutlined />} title='Categories' number={statData?.category} />
       </Col>
       <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <StatCard icon={<ReadFilled />} title='Courses' number={statData?.course} />
       </Col>
      </Row>

      <div style={{ marginTop: 30}}>
        <div>
          <p>By Date</p>
          <RangePicker defaultValue={[moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]} style={{ marginBottom: 20}} onChange={(_, date)=> getDasboardChart(date[0], date[1]) } />
        </div>
        <Row gutter={[10,10]}>
         <Col xs={24} sm={24} md={12} lg={12} xl={12}>
         <>
          <div className='mb-20'>
            <p className='mb-5'>Users</p>
            <Select value={type} style={{ width: 150}} onChange={(e) => setType(e)}>
              {chartTypes.map((type) => <Option key={type.value} value={type.value}>{type.label}</Option>)}
            </Select>
          </div>

          <div className='small-shadow' style={{ backgroundColor: 'white', borderRadius: 5, padding: 5, marginTop: 5, scrollBehavior:'auto'}}>
            <Charts type={type}  data={users} />
          </div>
        </>
         </Col>
         <Col xs={24} sm={24} md={12} lg={12} xl={12}>
         <>
          <div className='mb-20'>
            <p className='mb-5'>Trainers</p>
            <Select value={ttype} style={{ width: 150}} onChange={(e) => setTType(e)}>
              {chartTypes.map((type) => <Option key={type.value} value={type.value}>{type.label}</Option>)}
            </Select>
          </div>

          <div className='small-shadow' style={{ backgroundColor: 'white', borderRadius: 5, padding: 5, marginTop: 5, scrollBehavior:'auto'}}>
            <Charts type={ttype}  data={trainers} />
          </div>
        </>
         </Col>

        </Row>
        <Row style={{ marginTop: 20}} gutter={[10,10]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
           <>
            <div className='mb-20'>
              <p className='mb-5'>Students</p>
              <Select value={stype} style={{ width: 150}} onChange={(e) => setSType(e)}>
                {chartTypes.map((type) => <Option key={type.value} value={type.value}>{type.label}</Option>)}
              </Select>
            </div>

            <div className='small-shadow' style={{ backgroundColor: 'white', borderRadius: 5, padding: 5, marginTop: 5, scrollBehavior:'auto'}}>
              <Charts type={stype}  data={students} />
            </div>
            </>
           </Col>
        </Row>
      </div>

      <div style={{ marginTop: 20}}>
        <Row>
          <Col span={24}>
            <EventDetail />
          </Col>  
        </Row>
      </div>
    </div>
  )
}

export default Dashboard
