import React from 'react'
import { Card } from 'antd'
import './styles.scss'

const StatCard = ({icon, number , title}) => {
  return (
    <div id="statCard">
      <div class="data-card">
        <h3 style={{ fontSize: 20}}>{number}</h3>
        <h4 style={{ fontSize: 15, marginTop: 10}}>{title}</h4>
      </div>
    </div>
  )
}

export default StatCard
