import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Row, Col, Input, DatePicker, Table, Switch, Modal, Image } from "antd";
import { get, patch, deleteService } from "../../services/RestService";
import { DeleteFilled, EyeFilled, EditFilled } from "@ant-design/icons";
import { Button } from "antd";
import { downloadExcelFile, openNotification, options } from "../../helpers";
import { useHistory } from "react-router";

const { RangePicker } = DatePicker;

const Users = () => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [userData, setUser] = useState({});
  console.log({ userData });
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [viewModal, setViewModal] = useState(false);
  const [dataFilter, setDataFilter] = useState([]);

  const searchByName = (name) => {
    if (name !== "") {
      // console.log(name);
      const res = data.filter((sd) => {
        return (
          sd.firstName.toString().toLowerCase().includes(search) ||
          sd.email.toString().toLowerCase().includes(search)
        );
      });
      // console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const searchByDate = (dates) => {
    if (dates[0] !== "" && dates[1] !== "") {
      // console.log(dates);
      const res = data.filter((sd) => {
        return (
          sd?.registrationTime.substring(0, 10) >= dates[0] &&
          sd?.registrationTime.substring(0, 10) <= dates[1]
        );
      });
      // console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const getData = () => {
    setLoading(true);
    get("/admin/getUsers", options)
      .then((data) => {
        console.log("+++++user+++++", data);
        setData(data);
        setDataFilter(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const updateStatus = (id, checked) => {
    setLoading(true);

    const data = {
      userId: id,
      accountActive: checked,
    };
    patch("/admin/updateUser", data, options)
      .then((data) => {
        console.log(data);
        if (data.status) {
          openNotification("Status Changed Successfully");
          getData();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const deleteUser = (id) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("userId", id);
    deleteService(`/admin/deleteUser`, formData)
      .then((data) => {
        console.log(data);
        if (data.status) {
          openNotification("User Deleted Successfully");
          getData();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Image",
      dataIndex: "imagePath",
      key: "imagePath",
      render: (_, data) => (
        <Image
          style={{ width: 50, height: 50, objectFit: "contain" }}
          src={data && data.imagePath}
        />
      ),
    },
    {
      title: "Registration_Date",
      dataIndex: "registrationTime",
      key: "registrationTime",
      render: (_, data) => (
        <p>{data.registrationTime && data.registrationTime.substring(0, 10)}</p>
      ),
    },
    {
      title: "First_Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (_, data) => <p>{data.firstName && data.firstName}</p>,
    },
    {
      title: "Last_Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (_, data) => <p>{data.lastName && data.lastName}</p>,
    },
    {
      title: "Email",
      dataIndex: "lastName",
      key: "lastName",
      render: (_, data) => <p>{data.email && data.email}</p>,
    },
    {
      title: "Phone",
      dataIndex: "phoneNo",
      key: "phoneNo",
      render: (_, data) => <p>{data.phoneNo && data.phoneNo}</p>,
    },
    {
      title: "Country",
      dataIndex: "nationality",
      key: "nationality",
      render: (_, data) => <p>{data.nationality && data.nationality}</p>,
    },
    {
      title: "Residence",
      dataIndex: "residenceCountry",
      key: "residenceCountry",
      render: (_, data) => (
        <p>{data.residenceCountry && data.residenceCountry}</p>
      ),
    },
    {
      title: "Status",
      dataIndex: "accountActive",
      key: "accountActive",
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "InActive",
          value: false,
        },
      ],
      render: (_, data) => (
        <Switch
          checked={data.accountActive}
          onChange={(e) => updateStatus(data._id, e)}
        />
      ),
      onFilter: (value, data) => data.accountActive === value,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, data) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <EyeFilled
            onClick={() => {
              setUser(data);
              setViewModal(true);
            }}
            style={{
              color: "grey",
              cursor: "pointer",
              fontSize: 25,
              marginRight: 10,
            }}
          />
          <EditFilled
            onClick={() => history.push(`userform?type=edit&id=${data._id}`)}
            style={{
              color: "grey",
              cursor: "pointer",
              fontSize: 25,
              marginRight: 10,
            }}
          />
          <DeleteFilled
            onClick={() => {
              deleteUser(data._id);
            }}
            style={{ color: "grey", cursor: "pointer", fontSize: 25 }}
          />
        </div>
      ),
    },
  ];

  return (
    <div id="users" style={{ padding: 30 }}>
      <div className="users-wrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Users</h1>
          <div>
            <Button
              type="primary"
              onClick={() => downloadExcelFile(data, "users")}
            >
              Download
            </Button>
            <Button
              onClick={() => history.push("userform")}
              style={{ marginLeft: 30 }}
              type="primary"
            >
              Create User
            </Button>
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <Row gutter={[10, 10]}>
            {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div>
              <p>Filter By Status</p>
              <>
                <Select defaultValue="Select Option" style={{ width: '100%' }} onChange={handleChange}>
                  <Option value="all">All</Option>
                  <Option value="true">Active</Option>
                  <Option value="false">Inactive</Option>
                </Select>
              </>
            </div>
          </Col> */}
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div>
                <p>Search By Name or Email</p>
                <Input
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    searchByName(e.target.value);
                  }}
                  placeholder="Search By Name or Email"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <p>Search by Date</p>
              <RangePicker
                style={{ width: "100%" }}
                onChange={(e, d) => searchByDate(d)}
              />
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: 30 }}>
          <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={data}
          />
        </div>
      </div>
      <Modal
        footer={false}
        title="View User"
        okText="Done"
        visible={viewModal}
        onCancel={() => setViewModal(false)}
        onOk={() => {
          setViewModal(false);
        }}
      >
        <div>
          <center>
            <Image
              style={{ width: 100, height: 100, marginBottom: 30 }}
              src={userData?.imagePath}
            />
          </center>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Student Id</p>
              </b>
              <p>{userData?._id}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Registration Date</p>
              </b>
              <p>{userData?.registrationTime}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>First Name</p>
              </b>
              <p>{userData?.firstName}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Last Name</p>
              </b>
              <p>{userData?.lastName}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Email</p>
              </b>

              <p>{userData?.email}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Phone</p>
              </b>
              <p>{userData?.phoneNo}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Status</p>
              </b>
              <p>{userData?.accountActive ? "Active" : "Non-Active"}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Gender</p>
              </b>
              <p>{userData?.gender}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Residence</p>
              </b>
              <p>{userData?.residenceCountry}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Country</p>
              </b>
              <p>{userData?.nationality}</p>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default Users;
