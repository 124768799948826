
import './App.scss';
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom"
import SiderLayout from './Components/Sider';
import Login from './Pages/Login';
import { Provider } from 'react-redux'
import store from "./redux/store";
import ProtectedRoute from './services/ProtectedRoutes';
import Dashboard from './Pages/Dashboard';
import Users from './Pages/Users';
import UserForm from './Pages/Users/UserForm';
import Students from './Pages/Students';
import Trainers from './Pages/Trainers';
import Categories from './Pages/Categories';
import Courses from './Pages/Courses';
import CourseForm from './Pages/Courses/CourseForm';
import Jobs from './Pages/JobsManagement';
import Rating from './Pages/Rating';
import Promotions from './Pages/Promotions';
import Offers from './Pages/Offers';
import OfferForm from './Pages/Offers/OfferForm';
import Notifications from './Pages/Notifications';
import Documents from './Pages/Documents';
import TransactionHistory from './Pages/TransactionHistory';
import SiteSetting from './Pages/SiteSettings';
import About from './Pages/WebsitePages/About';
import Contact from './Pages/WebsitePages/Contact';
import Footer from './Pages/WebsitePages/Footer';
import Gallery from './Pages/WebsitePages/Gallery';
import Header from './Pages/WebsitePages/Header';
import Home from './Pages/WebsitePages/Home';
import Test from './Pages/Test';
import Questions from './Pages/Questions';
import CancelBookingRequest from './Pages/CancelBookingRequest';
import TermsCondition from './Pages/WebsitePages/TermsCondition';
import PrivacyPolicy from './Pages/WebsitePages/PrivacyPolicy';
import TrainerPromotion from './Pages/TrainerPromotion';
import ScheduleTestHistory from './Pages/ScheduleTestHistory';

function App() {
  const token = localStorage.getItem('token')
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path='/'>
            {token ? <Redirect to='/home' /> : <Login />}
          </Route>
          <SiderLayout>
            <ProtectedRoute exact path="/home" component={Dashboard} />
            <ProtectedRoute exact path="/users" component={Users} />
            <ProtectedRoute exact path="/userform" component={UserForm} />
            <ProtectedRoute exact path="/students" component={Students} />
            <ProtectedRoute exact path="/trainers" component={Trainers} />
            <ProtectedRoute exact path="/categories" component={Categories} />
            <ProtectedRoute exact path="/courses" component={Courses} />
            <ProtectedRoute exact path="/courseform" component={CourseForm} />
            <ProtectedRoute exact path="/jobs" component={Jobs} />
            <ProtectedRoute exact path="/rating" component={Rating} />
            <ProtectedRoute exact path="/promotions" component={Promotions} />
            <ProtectedRoute exact path="/offers" component={Offers} />
            <ProtectedRoute exact path="/offerform" component={OfferForm} />
            <ProtectedRoute exact path="/notifications" component={Notifications} />
            <ProtectedRoute exact path="/documents" component={Documents} />
            <ProtectedRoute exact path="/transaction-history" component={TransactionHistory} />
            <ProtectedRoute exact path="/site-setting" component={SiteSetting} />
            <ProtectedRoute exact path="/homesetting" component={Home} />
            <ProtectedRoute exact path="/about" component={About} />
            <ProtectedRoute exact path="/contact" component={Contact} />
            <ProtectedRoute exact path="/footer" component={Footer} />
            <ProtectedRoute exact path="/gallery" component={Gallery} />
            <ProtectedRoute exact path="/header" component={Header} />
            <ProtectedRoute exact path="/test" component={Test} />
            <ProtectedRoute exact path="/questions" component={Questions} />
            <ProtectedRoute exact path="/schedule-test" component={ScheduleTestHistory} />
            <ProtectedRoute exact path="/cancel-booking" component={CancelBookingRequest} />
            <ProtectedRoute exact path="/terms" component={TermsCondition} />
            <ProtectedRoute exact path="/privacy-policy" component={PrivacyPolicy} />
            <ProtectedRoute exact path="/trainer-promotion" component={TrainerPromotion} />
          </SiderLayout>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
