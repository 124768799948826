import { useEffect } from "react";
import { Form, Input, Button } from "antd";
import Logo from "../../assets/logocivil.png";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../redux/actions/authAction";
import { useHistory } from "react-router";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const onFinish = (values) => {
    dispatch(loginUser(values));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    auth.isAuthenticated && history.push("/home");
  }, [auth, history]);

  useEffect(() => {
    auth.isAuthenticated && window.location.reload();
  }, [auth]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={Logo} alt="logo" style={{ marginBottom: 30, marginTop: 100 }} />
      <p style={{ color: "black" }}>{auth.error}</p>
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <div style={{ textAlign: "center" }}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default Login;
