import React, { useState, useEffect } from "react";
import { Row, Col, Input, Table, Modal } from "antd";
import { get, deleteService } from "../../services/RestService";
import { DeleteFilled, EyeFilled, EditFilled } from "@ant-design/icons";
import { Button } from "antd";
import {
  downloadExcelFile,
  openNotification,
  useQueryParams,
  options,
} from "../../helpers";
import FormModal from "./FormModal";
import CreateModal from "./CreateForm";

const Questions = () => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const searchData =
    (data &&
      data.filter((sd) =>
        sd?.question.toString().toLowerCase().includes(search)
      )) ||
    [];
  const [viewModal, setViewModal] = useState(false);
  const [createViewModal, setCreateViewModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [method, setMethod] = useState();
  const query = useQueryParams();
  const testId = query.get("id");

  const getData = () => {
    setLoading(true);
    get(`/admin/getTestQuestions?testId=${testId}`, options)
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const deleteUser = (id) => {
    console.log('repeat')
    setLoading(true);
    const data = {
      questionId: id,
    };
    deleteService(`/admin/deleteQuestion`, data)
      .then((data) => {
        console.log(data);
        openNotification("Question Deleted Successfully");
        getData();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      render: (_, data) => <p>{data?.question} </p>,
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      render: (_, data) => <p>{data?.answer} </p>,
    },
    {
      title: "Option A",
      dataIndex: "optionA",
      key: "optionA",
      render: (_, data) => <p>{data?.optionA}</p>,
    },
    {
      title: "Option B",
      dataIndex: "optionB",
      key: "optionB",
      render: (_, data) => <p>{data?.optionB}</p>,
    },
    {
      title: "Option C",
      dataIndex: "optionC",
      key: "optionC",
      render: (_, data) => <p>{data?.optionC}</p>,
    },
    {
      title: "Option D",
      dataIndex: "optionD",
      key: "optionD",
      render: (_, data) => <p>{data?.optionD}</p>,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, data) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <EyeFilled
            onClick={() => {
              setModalData(data);
              setMethod("view");
              setViewModal(true);
            }}
            style={{
              color: "grey",
              cursor: "pointer",
              fontSize: 25,
              marginRight: 10,
            }}
          />
          <EditFilled
            onClick={() => {
              setModalData(data);
              setMethod("edit");
              setViewModal(true);
            }}
            style={{
              color: "grey",
              cursor: "pointer",
              fontSize: 25,
              marginRight: 10,
            }}
          />
          <DeleteFilled
            onClick={() => {
              deleteUser(data._id);
            }}
            style={{ color: "grey", cursor: "pointer", fontSize: 25 }}
          />
        </div>
      ),
    },
  ];

  return (
    <div id="users" style={{ padding: 30 }}>
      <div className="users-wrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Questions</h1>
          <div>
            <Button
              type="primary"
              onClick={() => downloadExcelFile(data, "questions")}
            >
              Download
            </Button>
            <Button
              onClick={() => {
                setMethod("create");
                setCreateViewModal(true);
              }}
              style={{ marginLeft: 30 }}
              type="primary"
            >
              Create Question
            </Button>
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div>
                <p>Search by Question</p>
                <Input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search By Question"
                />
              </div>
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: 30 }}>
          <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={searchData}
          />
        </div>
      </div>
      <Modal
        destroyOnClose
        footer={false}
        width={800}
        title="Question"
        okText="Done"
        visible={viewModal}
        onCancel={() => setViewModal(false)}
        onOk={() => {
          setViewModal(false);
        }}
      >
        <FormModal
          testId={testId}
          closeModal={() => setViewModal(false)}
          getTableData={getData}
          data={modalData}
          method={method}
        />
      </Modal>

      <Modal
        destroyOnClose
        footer={false}
        title="Question"
        okText="Done"
        width={800}
        visible={createViewModal}
        onCancel={() => setCreateViewModal(false)}
        onOk={() => {
          setCreateViewModal(false);
        }}
      >
        <CreateModal
          testId={testId}
          closeModal={() => setCreateViewModal(false)}
          getTableData={getData}
          data={modalData}
          method={method}
        />
      </Modal>
    </div>
  );
};

export default Questions;
