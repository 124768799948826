import React, { useState, useEffect } from "react";
import { Row, Col, Input, Table, Modal, DatePicker } from "antd";
import { get, patch, deleteService, post } from "../../services/RestService";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Button } from "antd";
import UploadFile from "../../Components/UploadFile";
import { openNotification, options } from "../../helpers";
import FormInput from "../../Components/FormInput";

const { RangePicker } = DatePicker;

const Documents = () => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [dataFilter, setFilterData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewModal, setViewModal] = useState(false);

  const [method, setMethod] = useState();
  const [file, setFile] = useState();
  const [title, setTitle] = useState();
  const [id, setId] = useState();

  const searchByName = (name) => {
    if (name !== "") {
      console.log(name);
      const res = data.filter((sd) => {
        return sd?.title?.toString().toLowerCase().includes(search);
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const searchByDate = (dates) => {
    if (dates[0] !== "" && dates[1] !== "") {
      console.log(dates);
      const res = data.filter((sd) => {
        return (
          sd?.createdAt.substring(0, 10) >= dates[0] &&
          sd?.createdAt.substring(0, 10) <= dates[1]
        );
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const getData = () => {
    setLoading(true);
    get("/admin/getDocuments", options)
      .then((data) => {
        setData(data.reverse());
        setFilterData(data.reverse());
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const createDocument = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("documentFile", file);
    formData.append("type", "file");
    console.log(options);
    post("/admin/createDocument", formData, options)
      .then((data) => {
        openNotification("Document Created Successfully");
        getData();
        setViewModal(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const updateDocument = () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("documentId", id);
    formData.append("title", title);
    formData.append("documentFile", file);
    formData.append("type", "file");
    patch("/admin/updateDocument", formData, options)
      .then((data) => {
        console.log(data);
        if (data.status) {
          openNotification("Document Updated Successfully");
          getData();
          setViewModal(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const deleteUser = (id) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("documentId", id);
    deleteService(`/admin/deleteDocument`, formData)
      .then((data) => {
        console.log(data);
        if (data.status) {
          openNotification("Document Deleted Successfully");
          getData();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, data) => <p>{data && data.title}</p>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, data) => <p>{data && data?.createdAt.substring(0, 10)}</p>,
    },
    {
      title: "File",
      dataIndex: "documentFilePath",
      key: "documentFilePath",
      render: (_, data) => (
        <p>{data.documentFilePath && data.documentFilePath}</p>
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, data) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <EditFilled
            onClick={() => {
              setId(data._id);
              setTitle(data.title);
              setFile(data.documentFilePath);
              setMethod("edit");
              setViewModal(true);
            }}
            style={{
              color: "grey",
              cursor: "pointer",
              fontSize: 25,
              marginRight: 10,
            }}
          />
          <DeleteFilled
            onClick={() => {
              deleteUser(data._id);
            }}
            style={{ color: "grey", cursor: "pointer", fontSize: 25 }}
          />
        </div>
      ),
    },
  ];

  return (
    <div id="users" style={{ padding: 30 }}>
      <div className="users-wrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Documents</h1>
          <div>
            <Button type="primary">Download</Button>
            <Button
              onClick={() => {
                setMethod("create");
                setViewModal(true);
              }}
              style={{ marginLeft: 30 }}
              type="primary"
            >
              Create Document
            </Button>
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div>
                <p>Search by Title</p>
                <Input
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    searchByName(e.target.value);
                  }}
                  placeholder="Search By Title"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <p>Search by Date</p>
              <RangePicker
                style={{ width: "100%" }}
                onChange={(e, d) => searchByDate(d)}
              />
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: 30 }}>
          <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={data}
          />
        </div>
      </div>
      <Modal
        destroyOnClose
        footer={false}
        title="Document"
        okText="Done"
        visible={viewModal}
        onCancel={() => {
          setViewModal(false);
          setId();
          setFile();
          setTitle();
        }}
        onOk={() => {
          setViewModal(false);
        }}
      >
        <div>
          <UploadFile file={file} setFile={setFile} />
          <div>
            <p>Title</p>
            <FormInput
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <Button
            onClick={() => {
              method === "edit" ? updateDocument() : createDocument();
            }}
            type="primary"
            htmlType="button"
          >
            {method === "edit" ? "Edit" : "Create"} Document{" "}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Documents;
