import React, { useState, useEffect } from 'react'
import { Row, Col, Input, Table, Switch, Modal, Image, DatePicker } from 'antd';
import { get, patch, deleteService } from '../../services/RestService'
import { DeleteFilled, EyeFilled, EditFilled  } from '@ant-design/icons';
import { Button } from 'antd'
import { downloadExcelFile, openNotification } from '../../helpers';
import FormModal from './FormModal';

const { RangePicker } = DatePicker

const Categories = () => {
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [dataFilter, setFilterData] = useState([])
  const [viewModal, setViewModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [method, setMethod] = useState()
  
  const getData = () => {
    setLoading(true)
    const t = localStorage.getItem('token')
      const token = `Bearer ${t}`
      var options = {
        headers: { 
          'Authorization': token
        }
      }
      get('/admin/getCategories',options).then((data) => {
        setData(data.reverse())
        setFilterData(data.reverse())
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        console.log(err)
      })
  };

  const updateStatus = (id, checked) => {
    setLoading(true)
    const t = localStorage.getItem('token')
      const token = `Bearer ${t}`
      var options = {
        headers: { 
          'Authorization': token
        }
      }

      const data = {
        categoryId : id,
        activeStatus : checked
      }
      patch('/admin/updateCategory',data , options).then((data) => {
        console.log(data)
        if (data.status) {
          openNotification('Status Changed Successfully')
          getData()
        }
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        console.log(err)
      })
  };

  const deleteUser = (id) => {
    setLoading(true)
      const data = new FormData()
      data.append('categoryId', id)
      deleteService(`/admin/deleteCategory`,data ).then((data) => {
        openNotification('Category Deleted Successfully')
        getData()
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        console.log(err)
      })
  };

  useEffect(() => {
    getData()
  }, [])

  const searchByName = (name) => {
    if (name !== '') {
      console.log(name);
      const res = data.filter((sd) => {
        return sd?.nameEnglish.toString().toLowerCase().includes(search) || sd?.nameArabic.toString().toLowerCase().includes(search)
      });
      console.log(res)
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const searchByDate = (dates) => {
    if (dates[0] !== '' && dates[1] !== '' ) {
      console.log(dates);
      const res = data.filter((sd) => {
        return sd?.createdAt.substring(0,10) >= dates[0] && sd?.createdAt.substring(0,10) <= dates[1]
      });
      console.log(res)
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const columns = [
    {
      title: 'Image',
      dataIndex: 'imagePath',
      key: 'imagePath',
      render: (_, data) => <Image style={{ width: 50, height: 50, objectFit: 'contain'}} src={data && data.imagePath} />
    },
    {
      title: 'Created_At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, data) => <p>{data && data?.createdAt.substring(0, 10)}</p> 
    },
    {
      title: 'Name(English)',
      dataIndex: 'nameEnglish',
      key: 'nameEnglish',
      render : (_, data) => <p>{data.nameEnglish && data.nameEnglish}</p>
    },
    {
      title: 'Description(English)',
      dataIndex: 'descriptionEnglish',
      key: 'descriptionEnglish',
      render : (_, data) => <p>{data.descriptionEnglish && data.descriptionEnglish}</p>
    },
    {
      title: 'Name(Arabic)',
      dataIndex: 'nameArabic',
      key: 'nameArabic',
      render : (_, data) => <p>{data.nameArabic && data.nameArabic}</p>
    },
    {
      title: 'Description(Arabic)',
      dataIndex: 'descriptionArabic',
      key: 'descriptionArabic',
      render : (_, data) => <p>{data.descriptionArabic && data.descriptionArabic}</p>
    },
    {
      title: 'Status',
      dataIndex: 'activeStatus',
      key: 'activeStatus',
      render : (_, data) => <Switch checked={data.activeStatus} onChange={(e) => updateStatus(data._id, e)} />,
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'InActive',
          value: false,
        },
      ],
      onFilter: (value, data) => data.activeStatus === value
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render : (_, data) => <div style={{ display: 'flex', alignItems: 'center'}}>
                                <EyeFilled onClick={()=> {setModalData(data); setMethod('view'); setViewModal(true)}} style={{ color: 'grey' , cursor: 'pointer', fontSize: 25, marginRight: 10}} />
                                <EditFilled onClick={()=> {setModalData(data); setMethod('edit'); setViewModal(true)}} style={{ color: 'grey' , cursor: 'pointer', fontSize: 25, marginRight: 10}} />
                                <DeleteFilled onClick={()=> {deleteUser(data._id)}} style={{ color: 'grey' , cursor: 'pointer', fontSize: 25}} />
                            </div>
    }
  ]

  return (
    <div id='users' style={{ padding: 30}}>
      <div className='users-wrapper'>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <h1>Categories</h1>
          <div>
            <Button onClick={()=> downloadExcelFile(data, 'categories')} type='primary'>Download</Button>
            <Button onClick={()=>  {setMethod('create'); setViewModal(true)}} style={{ marginLeft:30}} type='primary'>Create Category</Button>
          </div>
        </div>

        <div style={{ marginTop: 30}}>
        <Row gutter={[10,10]}>
          {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div>
              <p>Filter By Status</p>
              <>
                <Select defaultValue="Select Option" style={{ width: '100%' }} onChange={handleChange}>
                  <Option value="All">All</Option>
                  <Option value="Active">Active</Option>
                  <Option value="Inactive">Inactive</Option>
                </Select>
              </>
            </div>
          </Col> */}
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div>
              <p>Search by Name</p>
              <Input value={search} onChange={(e)=> {setSearch(e.target.value);searchByName(e.target.value)}} placeholder='Search By Name' />
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <p>Search by Date</p>
            <RangePicker style={{ width: '100%'}} onChange={(e, d) => searchByDate(d)} />
          </Col>
        </Row>
        </div>

        <div style={{ marginTop: 30 }}>
          <Table scroll={{ x: true }} loading={loading} columns={columns} dataSource={data} />
        </div>
      </div>
      <Modal destroyOnClose footer={false} title='Category' okText='Done' visible={viewModal} onCancel={()=> setViewModal(false)} onOk={()=> {setViewModal(false)}}>
        <FormModal closeModal={()=> setViewModal(false)} getTableData={getData} data={modalData} method={method} />
      </Modal>
    </div>
  )
}

export default Categories
