import React, { useState, useEffect } from 'react'
import { Button, Form, Row, Col, Switch, Image } from 'antd'
import UploadImage from '../../Components/UploadImage'
import FormInput from '../../Components/FormInput'
import FormTextArea from '../../Components/FormTextarea'
import SelectBox from '../../Components/SelectBox'
import { countries } from '../../constants'
import { post, patch, get } from '../../services/RestService'
import { openNotification, useQueryParams, options } from '../../helpers'
import { useHistory } from 'react-router'
import { useMediaQuery } from 'react-responsive'

const CourseForm = () => {
  const [ image, setImage ] = useState()
  const [ cImage, setCImage ] = useState()
  const [ imagePath, setImagePath ] = useState()
  const [ cImagePath, setCImagePath ] = useState()
  const history = useHistory()
  const query = useQueryParams()
  const [ userData, setUserData] = useState()
  const method = query.get('type')
  const id = query.get('id')
  const edit  = method === 'edit'
  const view  = method === 'view'
  const create  = method === 'create'
  const isMobileScreen = useMediaQuery({ query: '(max-width: 680px)' })
  const [trainer, setTrainer] = useState([{value: '', title: ''}])
  const [categories, setCategories] = useState([{value: '', title: ''}])
  const [locations, setLocation] = useState([{ country: '', address: '', state: '', area: '' }])
  const [form] = Form.useForm()

  // handle location input change
  const handleInputChange = (e, name, index) => {
    const value = e
    const list = [...locations]
    list[index][name] = value
    setLocation(list)
  }

  //handle location click event of the Remove button
  const handleRemoveClick = index => {
   const list = [...locations];
   list.splice(index, 1);
   setLocation(list);
  };

  //handle location click event of the Add button
  const handleAddClick = () => {
    setLocation([...locations, { country: '', address: '', state: '', area: '' }])
  }

  useEffect(() => {
    get('/admin/getTrainers',options).then((data) => {
      const cdata = []
      data?.map((d)=> cdata.push({ value: d?.user?._id, title: `${d?.user?.firstName} ${d?.user?.lastName}`}))
      setTrainer(cdata)
    }).catch((err) => {
      console.log(err)
    })
  }, [])

  useEffect(() => {
    get('/admin/getCategories',options).then((data) => {
      const cdata = []
      data?.map((d)=> cdata.push({ value: d?._id, title: `${d?.nameEnglish} ${d?.nameArabic}`}))
      setCategories(cdata)
    }).catch((err) => {
      console.log(err)
    })

  }, [])

  const onSubmit = (values) => {

    values.locations = JSON.stringify(locations)
    console.log(values)
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    formData.append('courseImages', image)
    formData.append('companyLogoImage', cImage)

    post('/admin/createCourse', formData, options).then((res)=> {
      if(res.status) {
        openNotification(res.message)
        history.push('courses')
      } else {
        res?.error && openNotification(res.error, 'error')
      }
    }).catch((err) => {
      err?.error && openNotification(err.error, 'error')
    })
  }

  const getData = () => {

    get(`/admin/getCourse?id=${id}`, options).then((res)=> {
      console.log(res)
      setUserData(res)
      setImagePath(res?.imagesPath.length && res.imagesPath[0])
      setCImagePath(res?.companyLogoImagePath)
      setLocation(res.locations)
      form.setFieldsValue({ organizedTrainerUserId: res?.trainer?._id })
      form.setFieldsValue({ categoryId: res?.category?._id })
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    if(edit || view)
      getData()
  }, [edit, view])

  const onEdit = (values) => {

    values.locations = JSON.stringify(locations)
    console.log(values)
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    formData.append('courseId', userData._id)
    formData.append('courseImages', image ? image : imagePath)
    formData.append('companyLogoImage', cImage ? cImage : cImagePath)

    patch('/admin/updateCourse', formData, options).then((res)=> {
      if(res.status) {
        openNotification(res.message)
        history.push('courses')
      } else {
        res?.error && openNotification(res.error, 'error')
      }
    }).catch((err) => {
      err?.error && openNotification(err.error, 'error')
    })
  }

  return (
    <div style={{ padding: isMobileScreen ? 10 : 50}}>
      <div style={{backgroundColor: 'white', padding: 20}}>
        3

        {((edit && userData) || (view && userData) || create ) && <div style={{ marginTop: 20}}>
          <Form scrollToFirstError form={form} onFinish={edit ? onEdit : onSubmit} initialValues={ create ? { activeStatus: false} : userData}>
            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                {(image || imagePath) && <Image src={image ? URL.createObjectURL(image) : imagePath} style={{ width: 100, height: 100, objectFit: 'contain'}} />}
                {!view && <div style={{ marginTop: 20, marginBottom: 10}}>
                  <p>Upload Course Image</p>
                  <UploadImage image={image} setImage={setImage} />
                </div>}
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <p>Categories</p>
                    <SelectBox rules={[{ required: true, message: 'required' }]} data={categories} name='categoryId' />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <p>Price</p>
                    <FormInput name='price' rules={[{ required: true, message: 'Please enter price'}]} />
                  </Col>
                </Row>
                <div>
                  <p>Summary</p>
                  <FormInput name='summary' rules={[{ required: true, message: 'required' }]} />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                {(cImage || cImagePath) && <Image src={cImage ? URL.createObjectURL(cImage) : cImagePath} style={{ width: 100, height: 100, objectFit: 'contain'}} />}
                {!view && <div style={{ marginTop: 20, marginBottom: 10}}>
                  <p>Upload Company Logo</p>
                  <UploadImage image={cImage} setImage={setCImage} />
                </div>}
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <p>Trainer</p>
                    <SelectBox data={trainer} name='organizedTrainerUserId' rules={[{ required: true, message: 'required' }]} />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <p>Status</p>
                    <Form.Item name='activeStatus' valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <div>
                  <p>Institude</p>
                  <FormInput name='institute'/>
                </div>
              </Col>
            </Row>

            {locations.length && locations.map((loc, index) => (
            <>
              <Row gutter={[10,10]}>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <p>Country</p>
                  <SelectBox onChange={(e)=> {handleInputChange(e, 'country', index)}} country value={loc.country} data={countries}  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <p>Address</p>
                  <FormInput onChange={(e)=> handleInputChange(e.target.value, 'address', index)} value={loc.address} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <p>Region</p>
                  <FormInput onChange={(e)=> handleInputChange(e.target.value, 'state', index)} value={loc.state} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <p>Area</p>
                  <FormInput onChange={(e)=> handleInputChange(e.target.value, 'area', index)} value={loc.area}/>
                </Col>
              </Row>
              {!view && <Row style={{ marginBottom: 10}} gutter={[10,10]}>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <Button onClick={()=> handleRemoveClick(index)} type='primary' htmlType='button'>Remove location</Button>
                </Col>
              </Row>}
            </>
            ))}

            {!view && <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Button onClick={()=> handleAddClick()} type='primary' htmlType='button'>Add location</Button>
              </Col>
            </Row>}


            <Row style={{ padding: 30}} gutter={[50,50]}>
              <Col style={{  padding: 20, borderRadius:15, marginTop: 20}} xs={24} sm={24} md={24} lg={12} xl={12}>
                <h2 style={{ textAlign: 'center'}}>English</h2>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Name</p>
                    <FormInput name='nameEnglish' rules={[{ required: true, message: 'required' }]} />
                  </Col>
                </Row>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Course Information</p>
                    <FormTextArea name='courseInformationEnglish' rules={[{ required: true, message: 'required' }]} />
                  </Col>
                </Row>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Documents Required</p>
                    <FormTextArea name='documentsRequiredEnglish' rules={[{ required: true, message: 'required' }]} />
                  </Col>
                </Row>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Registration Proceedure</p>
                    <FormTextArea name='RegistrationProcedureEnglish' rules={[{ required: true, message: 'required' }]}/>
                  </Col>
                </Row>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Lecture Details</p>
                    <FormTextArea name='lectureDetailsEnglish' rules={[{ required: true, message: 'required' }]}/>
                  </Col>
                </Row>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Fees Details</p>
                    <FormTextArea name='feesDetailsEnglish' rules={[{ required: true, message: 'required' }]}/>
                  </Col>
                </Row>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Test Details</p>
                    <FormTextArea name='testDetailsEnglish' rules={[{ required: true, message: 'required' }]}/>
                  </Col>
                </Row>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Refund Policy For Online Payments</p>
                    <FormTextArea name='refundPolicyForOnlinePaymentsEnglish' rules={[{ required: true, message: 'required' }]}/>
                  </Col>
                </Row>
              </Col>


              <Col style={{  padding: 20, borderRadius:15, marginTop: 20}} xs={24} sm={24} md={24} lg={12} xl={12}>
                <h2 style={{ textAlign: 'center'}}>Arabic</h2>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Name</p>
                    <FormInput name='nameArabic' rules={[{ required: true, message: 'required' }]}/>
                  </Col>
                </Row>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Course Information</p>
                    <FormTextArea name='courseInformationArabic' rules={[{ required: true, message: 'required' }]}/>
                  </Col>
                </Row>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Documents Required</p>
                    <FormTextArea name='documentsRequiredArabic' rules={[{ required: true, message: 'required' }]}/>
                  </Col>
                </Row>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Registration Proceedure</p>
                    <FormTextArea name='RegistrationProcedureArabic' rules={[{ required: true, message: 'required' }]}/>
                  </Col>
                </Row>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Lecture Details</p>
                    <FormTextArea name='lectureDetailsArabic' rules={[{ required: true, message: 'required' }]}/>
                  </Col>
                </Row>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Fees Details</p>
                    <FormTextArea name='feesDetailsArabic' rules={[{ required: true, message: 'required' }]}/>
                  </Col>
                </Row>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Test Details</p>
                    <FormTextArea name='testDetailsArabic' rules={[{ required: true, message: 'required' }]}/>
                  </Col>
                </Row>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Refund Policy For Online Payments</p>
                    <FormTextArea name='refundPolicyForOnlinePaymentsArabic' rules={[{ required: true, message: 'required' }]}/>
                  </Col>
                </Row>
              </Col>
            </Row>

              {!view && <Button style={{ marginTop: 30}} type='primary' htmlType='submit' >{edit ? 'Edit' : 'Create'} Course</Button>}
          </Form>
        </div>}
      </div>
    </div>
  )
}

export default CourseForm
