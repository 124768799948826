import React, { useState, useEffect } from "react";
import { Row, Col, Input, Table, Switch, Image, Modal, DatePicker } from "antd";
import { get, patch } from "../../services/RestService";
import { EyeFilled } from "@ant-design/icons";
import { Button } from "antd";
import { downloadExcelFile, openNotification, options } from "../../helpers";
import { useHistory } from "react-router";

const { RangePicker } = DatePicker 

const TrainerPromotion = () => {
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");
  const [data, setData] = useState([]);
  const [dataFilter, setFilterData] = useState([])
  const [viewData, setViewData] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
 
    const searchByName = (name) => {
      if (name !== "") {
        console.log(name);
        const res = data.filter((sd) => {
          return  sd?.titleEnglish?.toString().toLowerCase().includes(search) ||
          sd?.titleArabic?.toString().toLowerCase().includes(search)
        });
        console.log(res);
        setData(res);
      } else {
        setData(dataFilter);
      }
    };

    const searchByTrainer = (name) => {
      if (name !== "") {
        console.log(name);
        const res = data.filter((sd) => {
          let fullName = sd?.trainer?.firstName + ' ' + sd?.trainer?.lastName
          return  fullName.toString().toLowerCase().includes(search1)
        });
        console.log(res);
        setData(res);
      } else {
        setData(dataFilter);
      }
    };
  
    const searchByDate = (dates) => {
      if (dates[0] !== "" && dates[1] !== "") {
        console.log(dates);
        const res = data.filter((sd) => {
          return (
            sd?.createdAt.substring(0, 10) >= dates[0] &&
            sd?.createdAt.substring(0, 10) <= dates[1]
          );
        });
        console.log(res);
        setData(res);
      } else {
        setData(dataFilter);
      }
    };

  const getData = () => {
    setLoading(true);
    const t = localStorage.getItem("token");
    const token = `Bearer ${t}`;
    var options = {
      headers: {
        Authorization: token,
      },
    };
    get("/admin/getTrainerPromotions", options)
      .then((data) => {
        console.log(data);
        setData(data.reverse());
        setFilterData(data.reverse())
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const updateStatus = (id, checked) => {
    setLoading(true);
    console.log(checked);
    const data = {
      promotionId: id,
    };
    patch(
      checked
        ? "/admin/activateTrainerPromotion"
        : "/admin/deActivateTrainerPromotion",
      data,
      options
    )
      .then((data) => {
        console.log(data);
        openNotification("Status Changed Successfully");
        getData();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Image",
      dataIndex: "promotionImage",
      key: "promotionImage",
      render: (_, data) => (
        <Image
          style={{ width: 50, height: 50, objectFit: "contain" }}
          src={data && data.promotionImage}
        />
      ),
    },
    {
      title: "Created_At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, data) => <p>{data && data?.createdAt.substring(0, 10)}</p>,
    },
    {
      title: "Title(English)",
      dataIndex: "titleEnglish",
      key: "titleEnglish",
      render: (_, data) => <p>{data.titleEnglish && data.titleEnglish}</p>,
    },
    {
      title: "Title(Arabic)",
      dataIndex: "titleArabic",
      key: "titleArabic",
      render: (_, data) => <p>{data.titleArabic && data.titleArabic}</p>,
    },
    {
      title: "Trainer",
      dataIndex: "trainer",
      key: "trainer",
      render: (_, data) => (
        <p>
          {data?.trainer?.firstName} {data?.trainer?.lastName}
        </p>
      ),
    },
    {
      title: "Trainer_Status",
      dataIndex: "activeStatus",
      key: "activeStatus",
      render: (_, data) => (
        <p>{data?.activeStatus ? "Active" : "Non-Active"}</p>
      ),
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "InActive",
          value: false,
        },
      ],
      onFilter: (value, data) => data.activeStatus === value,
    },
    {
      title: "Admin_Status",
      dataIndex: "activeByAdmin",
      key: "activeByAdmin",
      render: (_, data) => (
        <Switch
          checked={data.activeByAdmin}
          onChange={(e) => updateStatus(data._id, e)}
        />
      ),
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "InActive",
          value: false,
        },
      ],
      onFilter: (value, data) => data.activeByAdmin === value,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, data) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <EyeFilled
            onClick={() => {
              setViewData(data);
              setViewModal(true);
            }}
            style={{
              color: "grey",
              cursor: "pointer",
              fontSize: 25,
              marginRight: 10,
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div id="users" style={{ padding: 30 }}>
      <div className="users-wrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Trainer Promotion</h1>
          <div>
            <Button onClick={() => downloadExcelFile(data)} type="primary">
              Download
            </Button>
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div>
                <p>Search by Title</p>
                <Input
                  value={search}
                  onChange={(e) => {setSearch(e.target.value); searchByName(e.target.value)}}
                  placeholder="Search By Title"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div>
                <p>Search by Trainer</p>
                <Input
                  value={search1}
                  onChange={(e) => {setSearch1(e.target.value); searchByTrainer(e.target.value)}}
                  placeholder="Search By Trainer"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <p>Search by Date</p>
            <RangePicker style={{ width: '100%'}} onChange={(e, d) => searchByDate(d)} />
          </Col>
          </Row>
        </div>

        <div style={{ marginTop: 30 }}>
          <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={data}
          />
        </div>
      </div>
      <Modal
        footer={false}
        title="View Trainer Promotion"
        okText="Done"
        visible={viewModal}
        onCancel={() => setViewModal(false)}
        onOk={() => {
          setViewModal(false);
        }}
      >
        <div>
          <center>
            <Image
              style={{ width: 100, height: 100, marginBottom: 30 }}
              src={viewData?.promotionImage}
            />
          </center>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Trainer Name</p>
              </b>
              <p>
                {viewData?.trainer?.firstName} {viewData?.trainer?.lastName}
              </p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Created Date</p>
              </b>
              <p>{viewData?.createdAt}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Title (English)</p>
              </b>
              <p>{viewData?.titleEnglish}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Title (Arabic)</p>
              </b>
              <p>{viewData?.titleArabic}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Description (English)</p>
              </b>
              <p>{viewData?.englishDescription}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Description (Arabic)</p>
              </b>
              <p>{viewData?.arabicDescription}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Trainer Status</p>
              </b>
              <p>{viewData?.accountActive ? "Active" : "Non-Active"}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Admin Status</p>
              </b>
              <p>{viewData?.activeByAdmin ? "Active" : "Non-Active"}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Previous price</p>
              </b>
              <p>{viewData?.previousPrice}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>After price</p>
              </b>
              <p>{viewData?.afterDiscountPrice}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Discount</p>
              </b>
              <p>{viewData?.discount}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Payment Status</p>
              </b>
              <p>{viewData?.payment_status}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Start Date</p>
              </b>
              <p>{viewData?.startDate?.substring(0, 10)}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>End Date</p>
              </b>
              <p>{viewData?.endDate?.substring(0, 10)}</p>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default TrainerPromotion;
