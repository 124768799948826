import React, { useState, useEffect } from 'react'
import { Spin, Button, Upload, Table, Image, Modal } from 'antd'
import { DeleteFilled, EditFilled  } from '@ant-design/icons'
import { get, patch, post, deleteService } from '../../services/RestService'
import { options, openNotification } from '../../helpers'
import FormInput from '../../Components/FormInput'

const Gallery = () => {

  const [loading, setLoading] = useState()
  const [data, setData] = useState()
  const [selectedFile, setSelectedFile] = useState([])
  const [updatedFile, setUpdatedFile] = useState([])
  const [type, setType] = useState()
  const [id , setId] = useState()
  const [viewModal, setViewModal] = useState(false)
  const [title, setTitle] = useState()
  
  const getData = () => {
    setLoading(true)
      get('/admin/getGallerys',options).then((data) => {
        setData(data)
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  const createGallery = () => {
  
    const formData = new FormData();
    formData.append('type', type)
    if ( selectedFile ) {
			selectedFile.forEach(file => {
        formData.append( 'galleryImages', file.originFileObj)
      })
		}

    post('/admin/createGallery', formData, options).then((res)=> {
      console.log(res)
      if(res.status) {
        openNotification(res.message)
        setType()
        setSelectedFile([])
        getData()
      } else {
        openNotification(res.error, 'error')
      }
    }).catch((err) => {
      console.log(err)
      openNotification(err.error, 'error')
    })
  }

  const updateGalery = () => {
   
    const formData = new FormData();
    
    formData.append('galleryId', id)
    formData.append('type', title)
    if ( updatedFile ) {
			updatedFile.forEach(file => {
        formData.append( 'galleryImages', file.originFileObj)
      })
		}
   
    patch('/admin/updateGallery', formData, options).then((res)=> {
      console.log(res)
      if(res.status) {
        openNotification(res.message)
        getData()
      } else {
        openNotification(res.error, 'error')
      }
    }).catch((err) => {
      console.log(err)
      openNotification(err?.error, 'error')
    })
  }

  const deleteGallery = (id) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('galleryId', id)
     
      deleteService(`/admin/deleteGallery?galleryId=${id}`,formData).then((data) => {
        console.log(data)
        if (data.status) {
          openNotification('Gallery Deleted Successfully')
          getData()
        }
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        console.log(err)
      })
  };

  useEffect(() => {
    getData()
  }, [])

  const setImageList = (data) => {
    const imgList = []
    data.imagesPath.map((img,i) =>(imgList.push({
      uid: i,
      name: img,
      status: 'done',
      url: img,
    },)))
    setUpdatedFile(imgList)
  }

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, data) => <p>{data?.type}</p>
    },
    {
      title: 'Images',
      dataIndex: 'imagesPath',
      key: 'imagesPath',
      render: (_, data) => <div>
                            {data?.imagesPath.map((item)=> (
                              <Image style={{ width: 100, height: 100, objectFit: 'contain', marginRight: 10}} src={item} />
                            ))}
                           </div>
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render : (_, data) => <div style={{ display: 'flex', alignItems: 'center'}}>
                              <EditFilled onClick={()=> {setId(data._id); setImageList(data); setTitle(data.type); setViewModal(true)}} style={{ color: 'grey' , cursor: 'pointer', fontSize: 25, marginRight: 10}} />
                              <DeleteFilled onClick={()=> deleteGallery(data._id)}  style={{ color: 'grey' , cursor: 'pointer', fontSize: 25}} />
                            </div>
    }
  ]

  const dummyRequest = ({ _, onSuccess }) => {
    setTimeout(() => { onSuccess('ok') }, 0)
  }

  const fileSelected  = ({ file, fileList: newFileList }) => {
    setSelectedFile(newFileList);
  }

  const updatedFileSelected  = ({ file, fileList: newFileList }) => {
    setUpdatedFile(newFileList);
  }

  return ( loading ? <Spin style={{ marginTop: 20}} /> :
    <div style={{ padding: 30}}>
      <div style={{ backgroundColor: 'white', padding: 20}}>
        <h1>
          Gallery
        </h1>
        <div style={{ marginTop: 50}}>
          <div>
            <h3>
              Title
            </h3>
            {/* <SelectBox data={selectData} onChange={(e)=> setType(e)} /> */}
            <FormInput onChange={(e)=> setType(e.target.value)} />
            <Upload
              customRequest={dummyRequest}
              listType="picture-card"
              fileList={selectedFile}
              onChange={fileSelected}
            >
              {'+ Upload'}
           </Upload>
           <div style={{ textAlign: 'right'}}>
             <Button onClick={()=> createGallery()} type='primary' htmlType='button'>Create</Button>
           </div>
           <div style={{ marginTop: 20 }}>
             <Table loading={loading} dataSource={data} columns={columns} />
           </div>
          </div>
        </div>
      </div>
      <Modal visible={viewModal} destroyOnClose footer={null} onCancel={()=> setViewModal(false)}>
        <div>
        <h3>
              Title
            </h3>
            <FormInput value={title} onChange={(e)=> setTitle(e.target.value)} />
            <Upload
              customRequest={dummyRequest}
              listType="picture-card"
              fileList={updatedFile}
              onChange={updatedFileSelected}
            >
              {'+ Upload'}
           </Upload>
           <div style={{ textAlign: 'right'}}>
             <Button onClick={()=> updateGalery()} type='primary' htmlType='button'>Update</Button>
           </div>
        </div>
      </Modal>
    </div>
  )
}

export default Gallery
