import React, { useState, useEffect } from 'react'
import { Button, Form, Row, Col, Image } from 'antd'
import UploadImage from '../../Components/UploadImage'
import FormInput from '../../Components/FormInput'
import { post, patch } from '../../services/RestService'
import { openNotification } from '../../helpers'
import FormTextarea from '../../Components/FormTextarea'
import SelectBox from '../../Components/SelectBox'
import { countries } from '../../constants'

const gender = [
  { value: 'Male', title: 'Male'},
  { value: 'Female', title: 'Female'},
  { value: 'Other', title: 'Other'}
]

const role = [
  { value: 'All', title: 'All'},
  { value: 'Student', title: 'Students'},
  { value: 'Trainer', title: 'Trainers'}
]

const FormModal = ({ method, closeModal, getTableData, data }) => {
  const [ image, setImage ] = useState()
  const [ imagePath, setImagePath ] = useState()
  const [ userData, setUserData] = useState()
  const edit  = method === 'edit'
  const view  = method === 'view'
  const create  = method === 'create'

  const onSubmit = (values) => {

    const t = localStorage.getItem('token')
    const token = `Bearer ${t}`
    var options = {
      headers: { 
        'Authorization': token
      }
    }

    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    formData.append('promotionImage', image)
   
    post('/admin/createPromotion', formData, options).then((res)=> {
      console.log(res)
      if(res.status) {
        openNotification(res.message)
        closeModal()
        getTableData()
      } else {
        openNotification(res.error, 'error')
      }
    }).catch((err) => {
      console.log(err)
      openNotification(err.error, 'error')
    })
  }


  useEffect(() => {
    if(edit || view)
      setUserData(data)
      setImagePath(data.promotionImage)
  }, [edit, view, setUserData, data])


  const onEdit = (values) => {

    const t = localStorage.getItem('token')
    const token = `Bearer ${t}`
    var options = {
      headers: { 
        'Authorization': token
      }
    }
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    formData.append('promotionId', data._id)
    formData.append('promotionImage', image ? image : imagePath)

    patch('/admin/updatePromotion', formData, options).then((res)=> {
      console.log(res)
      if(res.status) {
        openNotification(res.message)
        closeModal()
        getTableData()
      } else {
        openNotification(res.error, 'error')
      }
    }).catch((err) => {
      console.log(err)
      openNotification(err.error, 'error')
    })
  }
  

  return (
    <div >
      <div >
        {(image || imagePath) && <Image style={{ width: 100, height: 100, objectFit: 'contain'}} src={image ? URL.createObjectURL(image) : imagePath} />}
        {!view && <div style={{ marginTop: 10}}>
          <p>Upload Image</p>
          <UploadImage image={image} setImage={setImage} />
        </div>}

        {((edit && userData) || (view && userData) || create ) && 
        <div style={{ marginTop: 20}}>
          <Form onFinish={edit ? onEdit : onSubmit} initialValues={userData}>

          <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <p>Select By Gender</p>
                <SelectBox data={gender} name='gender'/>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <p>Select By Country</p>
                <SelectBox country data={countries} name='country' />
              </Col>
            </Row>

            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <p>Select By User Role</p>
                <SelectBox data={role} name='userRole' />
              </Col>
            </Row>

            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <p>Title (English)</p>
                <FormInput name='titleEnglish'/>
              </Col>
            </Row>
            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <p>subject (English)</p>
                <FormInput name='subjectEnglish'/>
              </Col>
            </Row>
            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <p>Message (English)</p>
                <FormTextarea name='messageEnglish' />
              </Col>
            </Row>
            
            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <p>Title (Arabic)</p>
                <FormInput name='titleArabic'/>
              </Col>
            </Row>
            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <p>subject (Arabic)</p>
                <FormInput name='subjectArabic'/>
              </Col>
            </Row>
            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <p>Message (Arabic)</p>
                <FormTextarea name='messageArabic' />
              </Col>
            </Row>

            
              {!view && <Button type='primary' htmlType='submit' >{edit ? 'Edit' : 'Create'} Promotion</Button>}
          </Form>
        </div>}
      </div>

    </div>
  )
}

export default FormModal
