import React, { useState, useEffect } from 'react'
import { Button, Form, Row, Col, Image, DatePicker } from 'antd'
import UploadImage from '../../Components/UploadImage'
import FormInput from '../../Components/FormInput'
import FormTextArea from '../../Components/FormTextarea'
import SelectBox from '../../Components/SelectBox'
import { post, patch, get } from '../../services/RestService'
import { openNotification, useQueryParams, options } from '../../helpers'
import { useHistory } from 'react-router'
import { useMediaQuery } from 'react-responsive'
import moment from 'moment'

const OfferForm = () => {
  const [ image, setImage ] = useState()
  const [ imageUrl, setImageUrl] = useState()
  const [ sDate, setSDate] = useState()
  const [ eDate, setEDate] = useState()
  const history = useHistory()
  const query = useQueryParams()
  const [ userData, setUserData] = useState()
  const method = query.get('type')
  const id = query.get('id')
  const edit  = method === 'edit'
  const view  = method === 'view'
  const create  = method === 'create'
  const isMobileScreen = useMediaQuery({ query: '(max-width: 680px)' })
  const [categories, setCategories] = useState([{values: '', title: ''}])
  const [form] = Form.useForm()

  useEffect(() => {
    get('/admin/getCourses',options).then((data) => {
      const cdata = []
      data?.map((d)=> cdata.push({ value: d?._id, title: `${d?.nameEnglish} ${d?.nameArabic}`}))
      setCategories(cdata)
    }).catch((err) => {
      console.log(err)
    })

  }, [])

  const onSubmit = (values) => {

    console.log(values)
    const nowDate = moment(new Date()).format('YYYY-MM-DD')
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    formData.append('offerImage', image ? image : imageUrl)
    formData.append('startDate', sDate?.substring(0, 10) || nowDate)
    formData.append('endDate', eDate?.substring(0, 10) || nowDate)
    formData.append('promoCode', 'qwe')

    post('/admin/createOffer', formData, options).then((res)=> {
      if(res.status) {
        openNotification(res.message)
        history.push('offers')
      } else {
        openNotification(res.error, 'error')
      }
    }).catch((err) => {
      openNotification(err.error, 'error')
    })
  }

  const getData = () => {

    get(`/admin/getOffer?id=${id}`, options).then((res)=> {
      console.log(res)
      setUserData(res)
      setImageUrl(res?.imagePath)
      setSDate(res?.startDate)
      setEDate(res?.endDate)
      form.setFieldsValue({ courseId : res.course })
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    if(edit || view)
      getData()
  }, [edit, view])

  const onEdit = (values) => {
    console.log(values)
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    formData.append('offerId', userData._id)
    formData.append('startDate', sDate?.substring(0, 10))
    formData.append('endDate', eDate?.substring(0, 10))
    formData.append('offerImage', image)
    formData.append('promoCode', 'qwe')

    patch('/admin/updateOffer', formData, options).then((res)=> {
      if(res.status) {
        openNotification(res.message)
        history.push('offers')
      } else {
        openNotification(res.error, 'error')
      }
    }).catch((err) => {
      openNotification(err.error, 'error')
    })
  }
  

  return (
    <div style={{ padding: isMobileScreen ? 10 : 50}}>
      <div style={{backgroundColor: 'white', padding: 20}}>
      <h1><span style={{ marginRight: 5, cursor: 'pointer'}} onClick={()=> history.push('offers')}>Offers /</span>{edit ? 'Edit' : view ? 'View' : 'Create'} Offer</h1>
        {(image || imageUrl) && <div style={{ textAlign: 'center'}}>
            <Image src={image ? URL.createObjectURL(image) : imageUrl} style={{ width: 100, heigt: 100, objectFit: 'contain', }} />
          </div>}
        {((edit && userData) || (view && userData) || create ) && <div style={{ marginTop: 20}}>
          <Form form={form} onFinish={edit ? onEdit : onSubmit} initialValues={userData}>
            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                {!view && <div style={{ marginTop: 20, marginBottom: 10}}>
                  <p>Upload Offer Image</p>
                  <UploadImage image={image} setImage={setImage} />
                </div>}
              </Col>
            </Row>
                
            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <p>Course</p>
                <SelectBox data={categories} name='courseId' />
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <p>Price Before</p>
                <FormInput name='priceBefore' />
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <p>Price After</p>
                <FormInput name='priceAfter' />
              </Col>
            </Row>

            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <p>Discount</p>
                <FormInput  name='discount' addonAfter='%' />
              </Col>
              {/* <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <p>Promotion Code</p>
                <FormInput name='promoCode' />
              </Col> */}
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <p>Promotion Start Date</p>
                <DatePicker allowClear={false} style={{ width: '100%'}} value={moment(sDate)} onChange={(_, date) => setSDate(date)} />
              </Col>
            </Row>

            <Row gutter={[10,10]}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <p style={{ marginTop: 20 }}>Promotion End Date</p>
                <DatePicker allowClear={false}  style={{ width: '100%'}} value={moment(eDate)} onChange={(_, date) => setEDate(date)} />
              </Col>
            </Row>
        

            <Row style={{ padding: 30}} gutter={[50,50]}>
              <Col style={{  padding: 20, borderRadius:15, marginTop: 20}} xs={24} sm={24} md={24} lg={12} xl={12}>
                <h2 style={{ textAlign: 'center'}}>English</h2>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Title</p>
                    <FormInput name='nameEnglish' />
                  </Col>
                </Row>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Description</p>
                    <FormTextArea name='descriptionEnglish' />
                  </Col>
                </Row>
              </Col>


              <Col style={{  padding: 20, borderRadius:15, marginTop: 20}} xs={24} sm={24} md={24} lg={12} xl={12}>
                <h2 style={{ textAlign: 'center'}}>Arabic</h2>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Title</p>
                    <FormInput name='nameArabic' />
                  </Col>
                </Row>
                <Row gutter={[10,10]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>Description</p>
                    <FormTextArea name='descriptionArabic' />
                  </Col>
                </Row>
              
              </Col>
            </Row>

              {!view && <Button style={{ marginTop: 30}} type='primary' htmlType='submit' >{edit ? 'Edit' : 'Create'} Offer</Button>}
          </Form>
        </div>}
      </div>
    </div>
  )
}

export default OfferForm
