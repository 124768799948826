import React, { useState , useEffect } from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";

// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";
import { get } from '../../services/RestService'
import { options } from '../../helpers'
import FormInput from "../FormInput";
import { useHistory } from "react-router-dom";
import './styles.scss'

// import events from "./events";

export default function EventDetail() {
  let history = useHistory();
  const [data, setData] = useState()
  const [dataFilter, setDataFilter] = useState()

  const getData = () => {
    get('/admin/getNotifications', options)
      .then((data) => {
        console.log(data);
        const resData = [] 
        const notData = data
        notData && notData.map((data, index)=> resData.push({ title: data.title, date: data.createdAt.substring(0, 10), url: 'notifications' }))
        setData(resData);
        setDataFilter(resData)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchByName = (name) => {
    if (name !== '') {
      console.log(name);
      const res = data.filter((sd) => {
        return sd?.title.toString().toLowerCase().includes(name);
      });
      setData(res);
    } else {
      setData(dataFilter);
    }
  };
  
  useEffect(() => {
    getData()
  }, [])

  return (
    <div className='calender-wrapper'>
      <p>Search Events</p>
      <FormInput 
        onChange={(e) => {
          searchByName(e.target.value);
        }}
        placeholder="Search By Title"
      />
      <FullCalendar
        defaultView="dayGridMonth"
        dayMaxEventRows={2}
        header={{
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay"
        }}
        plugins={[dayGridPlugin]}
        // plugins={[dayGridPlugin, timeGridPlugin]}
        // events={events}
        eventClick={(arg)=> { arg.jsEvent.preventDefault();history.push('/notifications') }}
        events={data}
      />
    </div>
  );
}