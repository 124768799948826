import React, { useState, useEffect } from "react";
import { Row, Col, Input, DatePicker, Table, Tag } from "antd";
import { get, deleteService } from "../../services/RestService";
import { DeleteFilled } from "@ant-design/icons";
import { Button } from "antd";
import { options, openNotification } from "../../helpers";

const { RangePicker } = DatePicker;

const Rating = () => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [dataFilter, setFilterData] = useState([]);
  const [loading, setLoading] = useState(true);
  //const searchData =  (data && data.filter((sd) => sd.firstName.toString().toLowerCase().includes(search) || sd.email.toString().toLowerCase().includes(search))) || []

  const searchByName = (name) => {
    if (name !== "") {
      console.log(name);
      const res = data.filter((sd) => {
        return sd?.student?.firstName.toString().toLowerCase().includes(search);
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const searchByDate = (dates) => {
    if (dates[0] !== "" && dates[1] !== "") {
      console.log(dates);
      const res = data.filter((sd) => {
        return (
          sd?.createdAt.substring(0, 10) >= dates[0] &&
          sd?.createdAt.substring(0, 10) <= dates[1]
        );
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const getData = () => {
    setLoading(true);
    get("/admin/getReviews", options)
      .then((data) => {
        console.log(data);
        setData(data.reverse());
        setFilterData(data.reverse());
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteRating = (id) => {
    setLoading(true);
    const data = {
      reviewId: id,
    };
    deleteService(`/admin/deleteReview`, data, options)
      .then((data) => {
        console.log(data);
        openNotification("Rating Deleted Successfully");
        getData();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const columns = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, data) => (
        <p>{data?.createdAt && data?.createdAt.substring(0, 10)}</p>
      ),
    },
    {
      title: "Student",
      dataIndex: "student",
      key: "student",
      render: (_, data) => (
        <p>
          {data?.student?.firstName} {data?.student?.lastName}
        </p>
      ),
    },
    {
      title: "Course (English)",
      dataIndex: "course",
      key: "course",
      render: (_, data) => <p>{data?.course?.nameEnglish}</p>,
    },
    {
      title: "Course (Arabic)",
      dataIndex: "course",
      key: "course",
      render: (_, data) => <p>{data?.course?.nameArabic}</p>,
    },
    {
      title: "FeedBack",
      dataIndex: "feedback",
      key: "feedback",
      render: (_, data) => <p>{data?.feedback}</p>,
    },
    {
      title: "Ratings",
      dataIndex: "ratings",
      key: "ratings",
      render: (_, data) => <Tag color={"volcano"}>{data?.ratings}</Tag>,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, data) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <DeleteFilled
            onClick={() => {
              deleteRating(data._id);
            }}
            style={{
              color: "grey",
              cursor: "pointer",
              fontSize: 25,
              marginRight: 10,
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div id="users" style={{ padding: 30 }}>
      <div className="users-wrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Ratings</h1>
          <div>
            <Button type="primary">Download</Button>
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div>
                <p>Search by Student</p>
                <Input
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    searchByName(e.target.value);
                  }}
                  placeholder="Search By Student"
                />
              </div>
            </Col>
            {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div>
                <p>Search by Trainer</p>
                <Input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search By Trainer"
                />
              </div>
            </Col> */}
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <p>Search by Date</p>
              <RangePicker
                style={{ width: "100%" }}
                onChange={(e, d) => searchByDate(d)}
              />
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: 30 }}>
          <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={data}
          />
        </div>
      </div>
    </div>
  );
};

export default Rating;
