import React, { useState, useEffect } from "react";
import { Row, Col, Input, Table, Modal, Tag, Select, DatePicker } from "antd";
import { get, patch } from "../../services/RestService";
import { EyeFilled } from "@ant-design/icons";
import { options } from "../../helpers";
import { Button } from "antd";
import moment from 'moment'

const { RangePicker } = DatePicker;
const { Option } = Select;

const ScheduleTestHistory = () => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [dataFilter, setFilterData] = useState([]);
  const [userData, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [viewModal, setViewModal] = useState(false);

  const searchByName = (name) => {
    if (name !== "") {
      console.log(name);
      const res = data.filter((sd) => {
        const fullName = sd?.student?.firstName + ' ' + sd?.student?.lastName
        return fullName.toString().toLowerCase().includes(search);
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const searchByDate = (dates) => {
    if (dates[0] !== "" && dates[1] !== "") {
      const res = data.filter((sd) => {
        return (
          sd?.date.substring(0, 10) >= dates[0] &&
          sd?.date.substring(0, 10) <= dates[1]
        );
      });
      console.log(res);
      setData(res);
    } else {
      setData(dataFilter);
    }
  };

  const getData = () => {
    setLoading(true);
    get("/admin/bookedDrivingTests", options)
      .then((data) => {
        console.log(data);
        setData(data.reverse());
        setFilterData(data.reverse());
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };


  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Test",
      dataIndex: "test",
      key: "test",
      render: (_, data) => <p>{data?.testType?.testName}</p>,
    },
    {
      title: "Test Date",
      dataIndex: "date",
      key: "date",
      render: (_, data) => (
        <p>{moment(data?.date).format('YYYY-MM-DD, h:mm:ss a')}</p>
      ),
    },
    {
      title: "Student",
      dataIndex: "student",
      key: "student",
      render: (_, data) => <p>{data?.student?.firstName} {data?.student?.lastName}</p>,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, data) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <EyeFilled
            onClick={() => {
              setUser(data);
              setViewModal(true);
            }}
            style={{
              color: "grey",
              cursor: "pointer",
              fontSize: 25,
              marginRight: 10,
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div id="users" style={{ padding: 30 }}>
      <div className="users-wrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Scheduled Test History</h1>
          <div>
            <Button type="primary">Download</Button>
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div>
                <p>Search by Student</p>
                <Input
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    searchByName(e.target.value);
                  }}
                  placeholder="Search By Student"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <p>Search by Date</p>
              <RangePicker
                style={{ width: "100%" }}
                onChange={(e, d) => searchByDate(d)}
              />
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: 30 }}>
          <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={data}
          />
        </div>
      </div>
      <Modal
        footer={false}
        title="Scheduled Test"
        destroyOnClose
        okText="Done"
        visible={viewModal}
        onCancel={() => setViewModal(false)}
        onOk={() => {
          setViewModal(false);
        }}
      >
        <div>
          {/* <center><Image style={{ width: 100 , height: 100, marginBottom: 30}} src={userData?.imagePath} /></center> */}
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Created At</p>
              </b>
              <p>{userData?.createdAt?.substring(0, 10)}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Student</p>
              </b>
              <p>{userData?.student?.firstName} {userData?.student?.lastName}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Email</p>
              </b>
              <p>{userData?.student?.email}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Phone</p>
              </b>
              <p>{userData?.student?.phoneNo}</p>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Test Date and Time</p>
              </b>
              <p>{moment(userData?.date).format('MMMM Do YYYY, h:mm:ss a')}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <b>
                <p>Test</p>
              </b>
              <p>{userData?.testType?.testName}</p>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default ScheduleTestHistory;
